// @ts-nocheck
import {Drawer, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import MapIcon from '@mui/icons-material/Map'; // Icon for Map
import LocationCityIcon from '@mui/icons-material/LocationCity'; // Icon for Suburb
import InsightsIcon from '@mui/icons-material/Insights'; // Icon for Insight
import ChatIcon from '@mui/icons-material/Chat'; // Icon for Chat
import {useTheme} from '@mui/material/styles';
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux"
import {useTranslation} from 'react-i18next';
import CrimeIcon from '@mui/icons-material/Gavel'; // Example icon for Crime
import ReportIcon from '@mui/icons-material/Assessment'; // Example icon for Report
import SchoolIcon from '@mui/icons-material/School'; // Import for SchoolIcon
import PublicIcon from '@mui/icons-material/Public'; // Example icon for global representation

import {useDispatch} from "react-redux"
import {toggleDrawer} from "../../redux/ui";

const SideBar = () => {
    const drawOpen = useSelector(state => state?.ui?.drawerOpen || false)
    const theme = useTheme();
    const [t] = useTranslation();
    const dispatch = useDispatch()
    const location = useLocation();
    const handleClose = () => {
        dispatch(toggleDrawer())
    }

    const isCurrentPath = (path) => {
        if (path.includes("/suburb")) {
            return location.pathname.includes("/suburb")
        }
        return location.pathname === path;
    }


    return (
        <Drawer
            variant="temporary"
            anchor="left"
            open={drawOpen}
            onClose={handleClose}
            sx={{
                '& .MuiDrawer-paper': {
                    marginTop: '56px', // Adjust based on screen size
                    bgcolor: theme.palette.primary.main, // Use theme color
                    color: 'white', // Set the text color to white
                    '& .MuiListItemIcon-root': {
                        color: 'inherit', // Ensures icons inherit the same white color
                    }
                },
            }}
        >
            <List>
                <ListItem button component={Link} to="/">
                    <ListItemIcon>
                        <MapIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t("Dashboard")}/>
                </ListItem>
                <List component="div" disablePadding>
                    <ListItem button component={Link} to="/crime"
                              sx={{

                                  pl: 4
                              }}
                              style={{
                                  backgroundColor: (isCurrentPath("/crime") || isCurrentPath("/")) ? '#4da6c8' : 'inherit',
                              }}

                    >
                        <ListItemIcon>
                            <CrimeIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t("Crime Map")}/>
                    </ListItem>
                    <ListItem button component={Link} to="/abs2021"
                              sx={{
                                  pl: 4
                              }}
                              style={{backgroundColor: isCurrentPath("/abs2021") ? '#4da6c8' : 'inherit',}}>
                        <ListItemIcon>
                            <ReportIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t("ABS 2021")}/>
                    </ListItem>
                    <ListItem button component={Link} to="/school" sx={{pl: 4}}
                              style={{backgroundColor: isCurrentPath("/school") ? '#4da6c8' : 'inherit'}}>
                        <ListItemIcon>
                            <SchoolIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t("School Intake")}/>
                    </ListItem>
                </List>
                <ListItem button component={Link} to="/suburb/PERTH/" style={{
                    backgroundColor: (isCurrentPath("/suburb")) ? '#4da6c8' : 'inherit',
                }}>
                    <ListItemIcon>
                        <LocationCityIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t("Suburb")}/>
                </ListItem>
                <ListItem button component={Link} to="/countrybirth">
                    <ListItemIcon>
                        <InsightsIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t("Insight")}/>

                </ListItem>
                <List component="div" disablePadding>
                    <ListItem button component={Link} to="/countrybirth" sx={{pl: 4}}
                              style={{backgroundColor: isCurrentPath("/countrybirth") ? '#4da6c8' : 'inherit'}}>
                        <ListItemIcon>
                            <PublicIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t("Country Birth")}/>
                    </ListItem>
                </List>
                <ListItem button component={Link} to="/chat"
                          style={{backgroundColor: isCurrentPath("/chat") ? '#4da6c8' : 'inherit'}}>
                    <ListItemIcon>
                        <ChatIcon/>
                    </ListItemIcon>
                    <ListItemText primary={t("Chat")}/>
                </ListItem>
            </List>
        </Drawer>
    )
}

export default SideBar;