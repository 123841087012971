// @ts-nocheck
import {ApolloClient, InMemoryCache} from '@apollo/client';
import {split, HttpLink} from '@apollo/client';
import {getMainDefinition} from '@apollo/client/utilities';
import {setContext} from '@apollo/client/link/context';
import {WebSocketLink} from '@apollo/link-ws';

const authLink = setContext((_, {headers}) => {
    // Get the token from localStorage
    const token = localStorage.getItem('jwt');
    if (!token) {
        return {
            headers: {
                ...headers,
            }
        };
    }
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
        }
    }
});


const httpLink = new HttpLink({
    uri: process.env.REACT_APP_HASURA_ENDPOINTS,
});

const httpLinkWithAuthToken = authLink.concat(httpLink)

const wsLink = new WebSocketLink(({
    // @ts-ignore
    uri: process.env.REACT_APP_HASURA_ENDPOINTS_WS,
    options: {
        reconnect: true,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
    }
}));

const splitLink = split(
    ({query}) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLinkWithAuthToken,
);

const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
});

export default client;