export const CRIMES_MAP        = {
    "2012_13": {
        "mapTilesetId": "pascalsun.7lcgx66r",
        "mapLayerId": "2012_13_all_suburb",
    },
    "2013_14": {
        "mapTilesetId": "pascalsun.8enx3ees",
        "mapLayerId": "2013_14_all_suburb",
    },
    "2014_15": {
        "mapTilesetId": "pascalsun.5b49033p",
        "mapLayerId": "2014_15_all_suburb",
    },
    "2015_16": {
        "mapTilesetId": "pascalsun.8l496ezq",
        "mapLayerId": "2015_16_all_suburb",
    },
    "2016_17": {
        "mapTilesetId": "pascalsun.1llsrkqd",
        "mapLayerId": "2016_17_all_suburb",
    },
    "2017_18": {
        "mapTilesetId": "pascalsun.2uhs7wwb",
        "mapLayerId": "2017_18_all_suburb",
    },
    "2018_19": {
        "mapTilesetId": "pascalsun.35yrynmb",
        "mapLayerId": "2018_19_all_suburb",
    },
    "2019_20": {
        "mapTilesetId": "pascalsun.cgj0s4v5",
        "mapLayerId": "2019_20_all_suburb",
    },
    "2020_21": {
        "mapTilesetId": "pascalsun.9iwxglxt",
        "mapLayerId": "2020_21_all_suburb",
    },
    "2021_22": {
        "mapTilesetId": "pascalsun.7ppjwbfj",
        "mapLayerId": "2021_22_all_suburb",
    },
    "2022_23": {
        "mapTilesetId": "pascalsun.0qfd3o6j",
        "mapLayerId": "2022_23_all_suburb",
    },
    "2023_24": {
        "mapTilesetId": "pascalsun.8cv1dh8y",
        "mapLayerId": "2023_24_all_suburb",
    },
}

export const CRIME_TYPES = [
    'Non-Dwelling_Burglary',
    'Stealing',
    'Homicide',
    'Sexual_Offences',
    'Assault_(Family)',
    'Assault_(Non-Family)',
    'Threatening_Behaviour_(Family)',
    'Threatening_Behaviour_(Non-Family)',
    'Deprivation_of_Liberty',
    'Robbery',
    'Dwelling_Burglary',
    'Stealing_of_Motor_Vehicle',
    'Property_Damage',
    'Arson',
    'Graffiti',
    'Fraud_&_Related_Offences',
    'Breach_of_Violence_Restraint_Order',
    "Drug_Offences"
].sort()


export const CRIME_TYPES_MAP = {
    "Non-Dwelling_Burglary": "Non_Dwelling_Burglary",
    "Stealing": "Stealing",
    "Sexual_Offences": "Sexual_Offences",
    "Assault_(Family)": "Assault_Family",
    "Assault_(Non-Family)": "Assault_Non_Family",
    "Threatening_Behaviour_(Family)": "Threatening_Behaviour_Family",
    "Threatening_Behaviour_(Non-Family)": "Threatening_Behaviour_Non_Family",
    "Deprivation_of_Liberty": "Deprivation_of_Liberty",
    "Robbery": "Robbery",
    "Dwelling_Burglary": "Dwelling_Burglary",
    "Stealing_of_Motor_Vehicle": "Stealing_of_Motor_Vehicle",
    "Property_Damage": "Property_Damage",
    "Arson": "Arson",
    "Graffiti": "Graffiti",
    "Fraud_&_Related_Offences": "Fraud_Related_Offences",
    "Breach_of_Violence_Restraint_Order": "Breach_of_VRO",
    "Homicide": "Homicide",
    "Drug_Offences": "Drug_Offences"
}