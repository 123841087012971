// @ts-nocheck
import {gql} from '@apollo/client';

export const GET_SUBURB_CRIME_TYPES = gql`
    query getSuburbCrimeTypes($suburbName: String!, $offence: String) {
      crime_wacrime(where: {locality: {_eq: $suburbName}, offence: {_eq: $offence}}) {
        financial_year
        total
        january
        february
        march
        april
        may
        june
        july
        august
        september
        october
        november
        december
        
      }
    }
`;

export const GET_CRIME_MAX_SUBURB_YEAR = gql`
query GetCrimeMaxYear($suburbName: String!, $financialYear: String!) {
  crime_wacrime(where: {financial_year: {_eq: $financialYear}, locality: {_eq: $suburbName}}) {
    id
    total
    offence
    locality
  }
  Non_Dwelling_Burglary:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Non-Dwelling Burglary"}}) {
    aggregate {
      max {
        total
      }
    }
  }
    Stealing:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Stealing"}}) {
        aggregate {
        max {
            total
        }
        }
    }
    Sexual_Offences:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Sexual Offences"}}) {
        aggregate {
        max {
            total
        }
        }
    }
    Assault_Family:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Assault (Family)"}}) {
        aggregate {
        max {
            total
        }
        }
    }
        Drug_Offences:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Drug Offences"}}) {
        aggregate {
        max {
            total
        }
        }
    }
    Assault_Non_Family:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Assault (Non-Family)"}}) {
        aggregate {
        max {
            total
        }
        }
    }
    Threatening_Behaviour_Family:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Threatening Behaviour (Family)"}}) {
        aggregate {
        max {
            total
        }
        }
    }
    Threatening_Behaviour_Non_Family:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Threatening Behaviour (Non-Family)"}}) {
        aggregate {
        max {
            total
        }
        }
    }
    Deprivation_of_Liberty:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Deprivation of Liberty"}}) {
        aggregate {
        max {
            total
        }
        }
    }
    Robbery:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Robbery"}}) {
        aggregate {
        max {
            total
        }
        }
    }
    Dwelling_Burglary:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Dwelling Burglary"}}) {
        aggregate {
        max {
            total
        }
        }
    }
    Stealing_of_Motor_Vehicle:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Stealing of Motor Vehicle"}}) {
        aggregate {
        max {
            total
        }
        }
    }
    Property_Damage:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Property Damage"}}) {
        aggregate {
        max {
            total
        }
        }
    }
    Arson:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Arson"}}) {
        aggregate {
        max {
            total
        }
        }
    }
    Graffiti:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Graffiti"}}) {
        aggregate {
        max {
            total
        }
        }
    }
    Fraud_Related_Offences:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Fraud & Related Offences"}}) {
        aggregate {
        max {
            total
        }
        }
    }
    Breach_of_VRO:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Breach of Violence Restraint Order"}}) {
        aggregate {
        max {
            total
        }
        }
    }
  Homicide:crime_wacrime_aggregate(where: {financial_year: {_eq: $financialYear}, offence: {_eq: "Homicide"}}) {
    aggregate {
      max {
        total
      }
    }
  }
}
`;