// @ts-nocheck
import React, {useState, useEffect, useRef} from 'react';
import {Box, TextField, Button, Paper, Typography, Avatar} from '@mui/material';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next';
import axiosObj from "../../api/axios";
import {useSelector} from "react-redux";
import BotIcon from '@mui/icons-material/Android'; // You can choose any icon you like
import {GET_CHAT_RECORD} from "../../gql/get_chat";
import {useQuery} from "@apollo/client";
import FindPerth from "../../assets/imgs/findperth.png"


const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        padding: '1rem',
    },
})

interface Message {
    id: number;
    text: string;
}

const ChatPage: React.FC = () => {
    const classes = useStyles();
    const scrollRef = useRef(null);
    const [t] = useTranslation();
    const [messages, setMessages] = useState<Message[]>([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const role = useSelector(state => state?.login?.role || "unregistered_user")
    const userId = useSelector(state => state?.login?.userId || null)

    const {data: chatData} = useQuery(GET_CHAT_RECORD, {
        variables: {
            userId: userId
        }
    })

    useEffect(() => {
        if (chatData) {
            // {id: Date.now(), text: input, sender: 'user'};
            const messages = chatData?.chat_chatrecord?.map((message) => {
                return [{
                    id: `${message.id}-sender`,
                    text: message.query,
                    sender: "user"
                },
                    {
                        id: `${message.id}-bot`,
                        text: message?.answer || "No answer",
                        sender: "bot"
                    }]
            }).reduce((acc, val) => acc.concat(val), [])
            setMessages(messages)
        }
    }, [chatData]);

    const sendMessageToBot = async (messageText: string) => {
        setIsLoading(true);
        try {
            // use axiosObj to send message to bot with post
            const response = await axiosObj.post('/chat/bq/', {query: messageText});
            const data = response.data;
            return data.answer;
        } catch (error) {
            console.error('Error while communicating with the bot:', error);
            return "Sorry, I couldn't understand that.";
        } finally {
            setIsLoading(false);
        }
    };

    const messageStyle = (sender: 'user' | 'bot') => ({
        display: 'flex',
        justifyContent: sender === 'user' ? 'flex-end' : 'flex-start',
        mb: 1,
        textAlign: sender === 'user' ? 'right' : 'left',
    });

    const messageBoxStyle = (sender: 'user' | 'bot') => ({
        maxWidth: '70%',
        p: 1,
        bgcolor: sender === 'user' ? 'lightblue' : 'lightgrey',
        borderRadius: 2,
    });
    const handleSend = async () => {
        if (input.trim()) {
            const userMessage = {id: Date.now(), text: input, sender: 'user'};
            setMessages([...messages, userMessage]);

            const botReply = await sendMessageToBot(input);
            const botMessage = {id: Date.now() + 1, text: botReply, sender: 'bot'};
            setMessages(currentMessages => [...currentMessages, botMessage]);

            setInput('');
        }
    };
    useEffect(() => {
        if (scrollRef.current) {
            const {scrollHeight, clientHeight} = scrollRef.current;
            scrollRef.current.scrollTop = scrollHeight - clientHeight;
        }
    }, [messages]); // Dependency array includes 'messages'

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', height: "calc(100vh - 100px)", p: 1}}>
            <Paper elevation={3} sx={{flex: 1, overflowY: 'auto', mb: 2, p: 2}}
                   ref={scrollRef}
            >
                {messages.map(message => (
                    <Box key={message.id} sx={messageStyle(message.sender)}>
                        {message.sender === 'bot' && <Avatar sx={{mr: 1}} src={FindPerth}><BotIcon/></Avatar>}
                        <Box sx={messageBoxStyle(message.sender)}>
                            <Typography variant="body1">{message.text}</Typography>
                        </Box>
                    </Box>
                ))}
            </Paper>
            <Box sx={{display: 'flex'}}>
                <TextField
                    fullWidth
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                    placeholder={role === "unregistered_user" ? t("You will need to register to use this feature") : t("Type a message")}
                    variant="outlined"
                    disabled={isLoading}
                />
                <Button onClick={handleSend} variant="contained" sx={{ml: 1}}
                        disabled={isLoading || role === "unregistered_user"}>
                    {t("Send")}
                </Button>
            </Box>
        </Box>
    );
};

export default ChatPage;