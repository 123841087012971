// @ts-nocheck
import {gql} from '@apollo/client';

export const GET_CHAT_RECORD = gql`
    query getChatRecord($userId: Int!) {
      chat_chatrecord(where: {user_id: {_eq: $userId}}, order_by: {created_at: asc}) {
      id
        answer
        query
        updated_at
        user_id
      }
    }
`;