// @ts-nocheck
import axios from 'axios';


const axiosObj = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINTS,
});

const getAuthToken = () => {
    //      read from localstorage
    const token: string | null = localStorage.getItem('jwt');
    return token
}

axiosObj.interceptors.request.use(config => {
    const token = getAuthToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config
})

export default axiosObj