// @ts-nocheck
import React, {useState} from 'react';
import {Select, MenuItem, InputLabel, FormControl} from '@mui/material';
import {useTranslation} from 'react-i18next';

const LanguageSelector: React.FC = () => {
    const {i18n, t} = useTranslation();
    const [language, setLanguage] = useState<string>(i18n.language);

    const handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedLanguage = event.target.value as string;
        setLanguage(selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
    };

    return (
        <FormControl variant="outlined" sx={{m: 1, minWidth: 120}} hiddenLabel>
            <InputLabel htmlFor="language-selector">{t("language")}</InputLabel>
            <Select
                label={t("language")}
                value={language}
                onChange={handleLanguageChange}
                inputProps={{
                    name: 'language',
                    id: 'language-selector',
                }}
                sx={{color: 'white', '& .MuiSvgIcon-root': {color: 'white'}}} // Changes the text and icon color to white
            >
                <MenuItem value="en">🇬🇧 English</MenuItem>
                <MenuItem value="zh">🇨🇳 中文</MenuItem>

            </Select>
        </FormControl>
    );
};

export default LanguageSelector;
