// @ts-nocheck
// Initial state
import {jwtDecode} from "jwt-decode";

function isJWTAvailable() {
    const token = localStorage.getItem('jwt') || null;
    // Here, you can add more robust checks, for example,
    // decode and check if the token is expired
    if (token === null) return {
        isLogin: false,
        role: "unregistered_user",
        username: "",
        firstName: "",
        lastName: "",
        userId: ""
    }
    const decoded = jwtDecode(token)
    return {
        isLogin: true,
        role: decoded?.role,
        username: decoded?.username,
        firstName: decoded?.first_name,
        lastName: decoded?.last_name,
        userId: decoded?.user_id
    }
}

const initialState = isJWTAvailable()

// Actions types
const actionTypes = {
    SET_LOGIN: 'SET_LOGIN',
    SET_USER_PROFILE: 'SET_USER_PROFILE',
    LOGOUT: 'LOGOUT'
};

export const logout = () => ({
    type: actionTypes.LOGOUT
})

export const setLogin = () => (
    {
        type: actionTypes.SET_LOGIN
    }
)

export const setUserProfile = (role, username, firstName, lastName, userId) => (
    {
        type: actionTypes.SET_USER_PROFILE,
        payload:
            {
                role: role,
                username: username,
                firstName: firstName,
                lastName: lastName,
                userId: userId
            }
    }
)

// Reducer function
const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LOGIN:
            return {
                ...state,
                isLogin: true
            };
        case actionTypes.SET_USER_PROFILE:
            return {
                ...state,
                role: action.payload?.role,
                username: action.payload?.username,
                firstName: action.payload?.firstName,
                lastName: action.payload?.lastName,
                userId: action.payload?.userId
            };
        case actionTypes.LOGOUT:
            // remove jwt
            localStorage.removeItem("jwt")
            return {
                isLogin: false,
                role: "unregistered_user",
                username: "",
                firstName: "",
                lastName: "",
                userId: ""
            };
        default:
            return state;
    }
};

export default loginReducer