// @ts-nocheck

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const toggleDrawer = () => ({
    type: TOGGLE_DRAWER,
});

const initialState = {
    drawerOpen: false,
};

export default function uiReducer(state = initialState, action: any) {
    switch (action.type) {
        case TOGGLE_DRAWER:
            return {
                ...state,
                drawerOpen: !state.drawerOpen,
            };
        default:
            return state;
    }
}