// @ts-nocheck
import axios from 'axios';

export const refreshToken = async (req, res) => {
    const refreshJWT = localStorage.getItem('refreshJWT');
    if (refreshJWT) {
        const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINTS}/subscription/token/refresh/`, {refresh: refreshJWT});
        localStorage.setItem('jwt', res.data.access);
        localStorage.setItem('refreshJWT', res.data.refresh);
        return res.data.access;
    } else {
        // remove jwt also
        localStorage.removeItem('jwt');
    }
}

