// @ts-nocheck
import React, {useState, ChangeEvent, FormEvent} from 'react';
import {TextField, Button, Container, Typography, Box} from '@mui/material';
import axiosObj from "../../api/axios";
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {         // Adjust according to your UI
    }
}));

interface RegistrationFormState {
    username: string;
    password: string;
    first_name: string;
    last_name: string;
}

const RegistrationPage: React.FC = () => {
    const [t] = useTranslation()
    const classes = useStyles();
    const navigate = useNavigate()
    const [formState, setFormState] = useState<RegistrationFormState>({
        username: '', password: '',
        first_name: "", last_name: ""
    });
    const [errorMsg, setErrorMsg] = useState<string | null>(null); // Error message state
    const [hintMsg, setHintMsg] = useState<string | null>(null);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setFormState({
            ...formState,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();
        setHintMsg(null);
        setErrorMsg(null);
        axiosObj.post("/subscription/register/", formState)
            .then(res => {
                if (res.data?.status) {
                    setHintMsg(t("Successful registration, please go to login"))
                    //     redirect to login
                    setTimeout(
                        navigate('/login'), 3000
                    )
                }
            })
            .catch((err: any) => {
                setErrorMsg(err.response?.data?.msg || t("Unknown error, please retry"));
            });
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography component="h1" variant="h5">
                    {t("Register")}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label={t("Email Address")}
                        name="username"
                        autoComplete="email"
                        autoFocus
                        value={formState.username}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t("Password")}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={formState.password}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="firstName"
                        label={t("First Name")}
                        name="first_name"
                        autoComplete="given-name"
                        autoFocus
                        value={formState.first_name}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="lastName"
                        label={t("Last Name")}
                        name="last_name"
                        autoComplete="family-name"
                        value={formState.last_name}
                        onChange={handleChange}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        {t("Register")}
                    </Button>
                </Box>
                <Box>
                    <Typography>{t("Already have an account?")} <Link to="/login">{t("login")}</Link></Typography>
                </Box>
                <Box>
                    {errorMsg &&
                        <Typography sx={{mt: 2}} color="error">{errorMsg}</Typography>} {/* Display error message */}
                    {hintMsg && <Typography sx={{mt: 2}} color="info">{hintMsg}</Typography>}
                </Box>
            </Box>
        </Container>
    );
};

export default RegistrationPage;
