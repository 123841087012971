// @ts-nocheck
import React, {useEffect, useRef, useState} from "react";
import mapboxgl from "mapbox-gl";
import {isMobileOnly} from "react-device-detect";
import {
    Paper,
    Slider,
    Button,
    Box,
    Typography,
    TextField,
    Dialog, DialogTitle, DialogContent, Grid
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import {CRIMES_MAP, CRIME_TYPES} from "../../utils/constants"
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"
import {useTranslation} from "react-i18next"
import {Bar, Pie} from 'react-chartjs-2';
import ReactGA from "react-ga";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LockIcon from '@material-ui/icons/Lock';
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    Title,
    PointElement

} from 'chart.js';
import {useQuery} from "@apollo/client";
import {WA_SUBURBS, GET_SUBURB} from "../../gql/get_reports"
import {GET_SUBURB_CRIME_TYPES} from "../../gql/get_crime";
import {Line} from 'react-chartjs-2';
import {useSelector} from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale,
    BarElement,
    Title,
    PointElement,
    LineElement,
    Tooltip,
);


export const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '64px', // Height of the AppBar
        left: '0',
        right: '0',
        bottom: '0',
    },
    showFunction: {
        position: "absolute",
        right: "0",
        top: "64px",
        zIndex: 999,
        width: "300px",
        height: "20px",
    },
    searchBar: {
        position: "absolute",
        width: "280px",
        height: "50px",
        top: "84px",
        right: "0",
        zIndex: 999,
        backgroundColor: "white",
        borderRadius: "3px",
        padding: "10px",
        border: "10px"
    },

    slider: {
        position: "absolute",
        width: "260px",
        height: "20px",
        top: "144px",
        right: "0",
        zIndex: 999,
        backgroundColor: "white",
        padding: "10px 20px 10px 20px",
        border: "0"
    },
    rank: {
        position: "absolute",
        width: "280px",
        height: "40px",
        top: "174px",
        right: "0",
        zIndex: 998,
        backgroundColor: "white",
        padding: "10px",
        border: "10px"
    },
    crimeTypes: {
        position: "absolute",
        width: "280px",
        height: "50px",
        top: "234px",
        right: "0",
        zIndex: 999,
        backgroundColor: "white",
        padding: "10px",
        border: "10px"
    },
    title: {
        position: "absolute",
        width: "280px",
        height: "35px",
        top: "294px",
        right: "0",
        zIndex: 999,
        backgroundColor: "white",
        padding: "10px",
        border: "10px"
    },
    legend: {
        position: 'absolute',
        top: '344px',
        right: '0px',
        display: 'flex',
        flexDirection: 'row', // 横向布局
        justifyContent: 'center',
        alignItems: 'center',
        padding: "10px",
        backgroundColor: 'white',
        borderRadius: theme.shape.borderRadius,
        zIndex: 999,
        width: "280px"
    },
    legendTitle: {
        marginRight: theme.spacing(1), // 与颜色条之间的距离
    },
    legendItemColorBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    colorBar: {
        width: "200px", // 或根据需要调整
        height: 10,
        background: 'linear-gradient(to right, rgba(116,211,251, 1), rgba(158, 1, 66, 1))',
        marginRight: theme.spacing(1),
    },
    stateColorBar: {
        width: "200px", // 或根据需要调整
        height: 10,
        background: 'linear-gradient(to right, ' +
            '#e6f5d0 0%, ' + // Lightest green
            '#b3d99f 12.5%, ' + // 200/1600
            '#8ac16c 25%, ' + // 400/1600
            '#5c9e4b 37.5%, ' + // 600/1600
            '#3b7e2d 50%, ' + // 800/1600
            '#FEE08B 62.5%, ' + // 1000/1600
            '#FDAE61 75%, ' + // 1200/1600
            '#F46D43 87.5%, ' + // 1400/1600
            '#D53E4F 100%, ' + // 1600/1600
            '#9E0142 100%' + // Darkest red
            ')',
        marginRight: theme.spacing(1),
    },
}));


const CrimeMap: React.FC = () => {
    const classes = useStyles();
    const [map, setMap] = useState(null)
    const mapContainer = useRef(null)
    const [visibleCrimeYear, setVisibleCrimeYear] = useState("2023_24")
    const [selectedCrimeProperties, setSelectedCrimeProperties] = useState(null)
    const [crimeType, setCrimeType] = useState("Dwelling_Burglary")
    const [crimeRankType, setCrimeRankType] = useState("metro_rate")
    const {t} = useTranslation();
    const [showControls, setShowControls] = useState(true);
    const [metroSuburbs, setMetroSuburbs] = useState([])
    const [selectedSuburb, setSelectedSuburb] = useState(null)
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedCrimeYearly, setSelectedCrimeYearly] = useState(null)

    const userRole = useSelector(state => state?.login?.role)
    const handleClose = () => {
        setOpenDialog(false);
    };

    const toggleControls = () => {
        setShowControls(!showControls);
    };

    // select suburb functions
    const {data: metroSuburbsData} = useQuery(
        WA_SUBURBS
    )
    // update suburb options
    useEffect(() => {
        if (metroSuburbsData) {
            setMetroSuburbs(
                metroSuburbsData?.abs_abssuburbgeo
            )
        }
    }, [metroSuburbsData, setMetroSuburbs]);

    const {refetch: suburbRefetch} = useQuery(GET_SUBURB, {
        variables: {
            suburbName: selectedSuburb
        }
    })

    const {
        refetch: suburbCrimeTypeRefetch
    } = useQuery(GET_SUBURB_CRIME_TYPES, {
        variables: {
            suburbName: selectedCrimeProperties?.LOC_NAME,
            offence: crimeType.replace(/_/g, " ")
        }
    })

    useEffect(() => {
        const fetchSuburbCrimeType = async () => {
            if (selectedCrimeProperties) {
                try {
                    const offence = crimeType.replace(/_/g, " ")
                    const result = await suburbCrimeTypeRefetch({
                        variables: {
                            suburbName: selectedCrimeProperties?.LOC_NAME,
                            offence: offence
                        }
                    });
                    setSelectedCrimeYearly(result?.data?.crime_wacrime)
                } catch (error) {
                    console.error("Error refetching:", error);
                }

            }
        }
        fetchSuburbCrimeType();

    }, [crimeType, selectedCrimeProperties, setSelectedCrimeYearly]);

    const isCurrentMonth = (month: string) => {
        const currentMonth = new Date().getMonth() + 1;
        return month !== currentMonth.toString();
    }

    const prepareSelectedCrimeYearlyData = (selected_data) => {
        //     labels from financial_year key, remove 2023_2024
        // data from total field
        const labels = []
        const data = []
        const jan = []
        const feb = []
        const mar = []
        const apr = []
        const may = []
        const jun = []
        const jul = []
        const aug = []
        const sep = []
        const oct = []
        const nov = []
        const dec = []
        for (const key in selected_data) {
            if (selected_data[key].financial_year !== "2024-25") {
                labels.push(selected_data[key].financial_year)
                data.push(selected_data[key].total)
                jan.push(selected_data[key].january)
                feb.push(selected_data[key].february)
                mar.push(selected_data[key].march)
                apr.push(selected_data[key].april)
                may.push(selected_data[key].may)
                jun.push(selected_data[key].june)
                jul.push(selected_data[key].july)
                aug.push(selected_data[key].august)
                sep.push(selected_data[key].september)
                oct.push(selected_data[key].october)
                nov.push(selected_data[key].november)
                dec.push(selected_data[key].december)
            }
        }
        if (!isMobileOnly) {
            return {
                labels: labels,
                datasets: [
                    {
                        label: t("Total"),
                        data: data,
                        // backgroundColor will be the same for one suburb
                        backgroundColor: "rgba(255, 99, 132, 0.5)",
                        borderWidth: 1,

                    },
                    {
                        label: t("January"),
                        data: jan,
                        // get another color for the month
                        backgroundColor: "rgba(255, 159, 64, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("1")
                    },
                    {
                        label: t("February"),
                        data: feb,
                        // get another color for the month
                        backgroundColor: "rgba(255, 205, 86, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("2")
                    },
                    {
                        label: t("March"),
                        data: mar,
                        // get another color for the month
                        backgroundColor: "rgba(75, 192, 192, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("3")
                    },
                    {
                        label: t("April"),
                        data: apr,
                        // get another color for the month
                        backgroundColor: "rgba(54, 162, 235, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("4")
                    },
                    {
                        label: t("May"),
                        data: may,
                        // get another color for the month
                        backgroundColor: "rgba(153, 102, 255, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("5")
                    },
                    {
                        label: t("June"),
                        data: jun,
                        // get another color for the month
                        backgroundColor: "rgba(201, 203, 207, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("6")
                    },
                    {
                        label: t("July"),
                        data: jul,
                        // get another color for the month
                        backgroundColor: "rgba(255, 99, 132, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("7")
                    },
                    {
                        label: t("August"),
                        data: aug,
                        // get another color for the month
                        backgroundColor: "rgba(255, 159, 64, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("8")
                    },
                    {
                        label: t("September"),
                        data: sep,
                        // get another color for the month
                        backgroundColor: "rgba(255, 205, 86, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("9")
                    },
                    {
                        label: t("October"),
                        data: oct,
                        // get another color for the month
                        backgroundColor: "rgba(75, 192, 192, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("10")

                    },
                    {
                        label: t("November"),
                        data: nov,
                        // get another color for the month
                        backgroundColor: "rgba(54, 162, 235, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("11")
                    },
                    {
                        label: t("December"),
                        data: dec,
                        // get another color for the month
                        backgroundColor: "rgba(153, 102, 255, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("12")
                    }
                ],
            }
        } else {
            return {
                labels: labels,
                datasets: [
                    {
                        label: t("Total"),
                        data: data,
                        // backgroundColor will be the same for one suburb
                        backgroundColor: "rgba(255, 99, 132, 0.5)",
                        borderWidth: 1,

                    },

                ],
            }
        }

    }

    useEffect(() => {
        const fetchSuburb = async () => {
            if (selectedSuburb && map) {
                try {
                    const result = await suburbRefetch({
                        variables: {
                            suburbName: selectedSuburb
                        }
                    });

                    //@ts-ignore
                    const moveToPolygon = (polygon) => {
                        // @ts-ignore
                        const bounds = new mapboxgl.LngLatBounds();
                        // @ts-ignore
                        polygon.forEach((point) => {
                            bounds.extend(point);
                        });
                        // @ts-ignore
                        map.fitBounds(bounds, {
                            padding: 30,
                            maxZoom: 12
                        });

                    };
                    //@ts-ignore
                    map.getSource('selectedSuburbPolygon').setData({
                        'type': 'Feature',
                        'geometry': result?.data?.abs_abssuburbgeo?.[0]?.geometry,
                        'properties': {
                            'name': selectedSuburb // The text to display on the polygon
                        }
                    });
                    moveToPolygon(result?.data?.abs_abssuburbgeo?.[0]?.geometry?.coordinates)
                } catch (error) {
                    console.error("Error refetching:", error);
                }
            }
        };

        fetchSuburb();
    }, [selectedSuburb, map]);

    const fillCrimeColorMap = (crimeYear: string, crimeType: string, crimeRankType: string) => {
        return (crimeRankType === 'metro_rate' ? [
            'interpolate',
            ['linear'],
            ['get', `${crimeYear}.${crimeType}.TotalAnnual_MetroRateRank`],
            -1,
            'rgba(0, 0, 0, 0)',
            0,
            "rgba(116,211,251, 1)",
            347,
            'rgba(158, 1, 66, 1)',
        ] : (crimeRankType === "metro_count" ? [
            'interpolate',
            ['linear'],
            ['get', `${crimeYear}.${crimeType}.TotalAnnual_MetroRank`],
            -1,
            'rgba(0, 0, 0, 0)',
            0,
            "rgba(116, 211, 251, 1)",
            347,
            'rgba(158, 1, 66, 1)',
        ] : [
            "step",
            ['get', `${crimeYear}.${crimeType}.TotalAnnual_Rank`],
            '#e6f5d0', // Lightest green
            0,
            '#b3d99f',
            200,
            '#8ac16c',
            400,
            '#5c9e4b',
            600,
            '#3b7e2d', // Darkest green
            800,
            '#FEE08B', // Lightest yellow
            1000,
            '#FDAE61',
            1200,
            '#F46D43',
            1400,
            '#D53E4F',
            1600,
            '#9E0142', // Darkest red
        ]))
    }
    const handleSliderChange = (event: any, newValue: number | number[]) => {

        setVisibleCrimeYear(`${newValue}_{(newValue + 1)[2:}`)
        // @ts-ignore
        const oneMoreYear = (newValue + 1).toString().slice(2, 4);

        const newCrimeYear = `${newValue}_${oneMoreYear}`;
        setVisibleCrimeYear(newCrimeYear)
        setSelectedCrimeProperties(null)
    }
    const handleCrimeTypeChange = (event: any) => {
        setCrimeType(event.target.value)
    }
    // init the map
    useEffect(() => {
        let ignore = false
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY

        // @ts-ignore
        const initializeMap = ({setMap, mapContainer}) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/pascalsun/ckq0th5wt25e117p8smraill2", // style URL
                center: [115.8142283, -31.9810844],
                zoom: 10, // starting zoom
                minZoom: 4,
            })
            map.addControl(new mapboxgl.NavigationControl(), "bottom-left")
            map.on("load", () => {
                if (!ignore) setMap(map)
            })
        }
        if (!map) initializeMap({setMap, mapContainer})
        return () => {
            ignore = true
        }
    }, [map])


    const getSelectedCrimeAnnual = () => {
        const crimeAnnual = []
        for (const theCrimeType of CRIME_TYPES) {
            crimeAnnual.push(selectedCrimeProperties?.[`${visibleCrimeYear}.${theCrimeType}.TotalAnnual`])
        }

        return {
            labels: CRIME_TYPES.map((crimeType) => t(crimeType)),
            datasets: [
                {
                    label: t("Crime Annual"),
                    data: crimeAnnual,
                    backgroundColor: [
                        "#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850",
                        "#8d6e63", "#26a69a", "#ff7043", "#7e57c2", "#78909c",
                        "#42a5f5", "#7b1fa2", "#ffd54f", "#ff8a65", "#ba68c8",
                        "#f48fb1", "#b0bec5"
                    ],
                    borderWidth: 1,
                },
            ],
        }
    }

    // init the data layers on the map
    useEffect(() => {
        if (map) {
            // @ts-ignore
            map?.resize()

            // add the Crime layers
            // @ts-ignore
            for (const crimeYear in CRIMES_MAP) {
                // @ts-ignore
                const crimeLayer = map?.getLayer(`${crimeYear}Layer`)

                if (!crimeLayer) {
                    // @ts-ignore
                    map.addSource(`${crimeYear}Tileset`, {
                        type: 'vector',
                        // @ts-ignore
                        url: `mapbox://${CRIMES_MAP[crimeYear]?.mapTilesetId}`
                    });

                    // @ts-ignore
                    map.addLayer({
                        id: `${crimeYear}Layer`,
                        type: 'fill',
                        source: `${crimeYear}Tileset`,
                        // @ts-ignore
                        "source-layer": CRIMES_MAP[crimeYear]?.mapLayerId,
                        paint: {
                            'fill-color': fillCrimeColorMap(crimeYear, crimeType, crimeRankType),
                            'fill-opacity': 0.9
                        }
                    });

                    // @ts-ignore
                    map.setLayoutProperty(`${crimeYear}Layer`, "visibility", crimeYear === visibleCrimeYear ? "visible" : "none");

                }

                // on click
                // @ts-ignore
                map.on("click", `${crimeYear}Layer`, (e: { features: { properties: { [x: string]: any } }[] }) => {

                    if (e.features[0].properties?.LOC_NAME === selectedCrimeProperties?.LOC_NAME) {
                        setSelectedCrimeProperties(null)
                    } else {
                        // @ts-ignore
                        setSelectedCrimeProperties(e.features[0].properties)
                        setOpenDialog(true);
                        if (window.location.hostname !== "localhost") {
                            ReactGA.event({
                                category: "User",
                                action: `${crimeYear}Layer`,
                                label: `${e.features[0].properties?.LOC_NAME}`
                            });
                        }
                    }
                })
            }

            // add selected suburb layer
            //@ts-ignore
            const selectedSuburbPolygonLayer = map?.getLayer(`selectedSuburbPolygonLayer`)
            if (!selectedSuburbPolygonLayer) {
                // @ts-ignore
                map.addSource('selectedSuburbPolygon', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': [[]], // Empty coordinates
                        }
                    }
                });
                //@ts-ignore
                map.addLayer({
                    'id': 'selectedSuburbPolygonLayer',
                    'type': 'line',
                    'source': 'selectedSuburbPolygon',
                    'layout': {},
                    'paint': {
                        'line-color': '#000000',  // Border color of the polygon
                        'line-width': 5,           // Width of the polygon border
                    }
                });
                // @ts-ignore
                map.addLayer({
                    'id': 'selectedSuburbPolygonTextLayer',
                    'type': 'symbol',
                    'source': 'selectedSuburbPolygon',
                    'layout': {
                        'text-field': '{name}', // Assuming the feature property "name" has the text you want to display
                        'text-anchor': 'center',

                    },
                    "minzoom": 11,
                    'paint': {
                        'text-color': '#00008B' // Dark blue color
                    },
                })
            }
        }
    }, [map, crimeType, crimeRankType, visibleCrimeYear])


    // Crime
    useEffect(() => {
            if (map) {
                for (const crimeYear in CRIMES_MAP) {
                    // @ts-ignore
                    map?.setLayoutProperty(`${crimeYear}Layer`, "visibility", crimeYear === visibleCrimeYear ? "visible" : "none");
                }
            }
        }
        , [visibleCrimeYear, map])

    // Crime
    useEffect(() => {
        if (map) {
            // @ts-ignore
            const layer = map.getLayer(`${visibleCrimeYear}Layer`)
            if (layer) {
                // @ts-ignore
                map?.setPaintProperty(`${visibleCrimeYear}Layer`, 'fill-color', fillCrimeColorMap(visibleCrimeYear, crimeType, crimeRankType))
            }
        }
    }, [crimeType, map, visibleCrimeYear, crimeRankType])


    return (
        <>{
            showControls && <>
                <Autocomplete
                    className={classes.searchBar}
                    freeSolo
                    // @ts-ignore
                    options={metroSuburbs?.filter(suburb => suburb?.sal_name21).map(suburb => suburb.sal_name21)}
                    onChange={(event, newValue) => {
                        setSelectedSuburb(newValue);
                    }}
                    value={selectedSuburb}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("Search suburb")}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                style: {
                                    height: "50px",
                                    padding: "10px",
                                    border: "10px",
                                    width: "250px",
                                    alignItems: "center"
                                } // this adjusts the input height and padding inside
                            }}
                            InputLabelProps={{
                                style: {
                                    marginLeft: "20px" // adjust this value as needed
                                }
                            }}
                            style={{
                                margin: 0,
                                alignItems: "center"
                            }} // to ensure the label and input are correctly aligned
                        />
                    )}
                />

                <Box className={classes.slider}>
                    <Slider aria-label="Crime Year Selector"
                            min={2013}
                            value={parseInt(visibleCrimeYear.split("_")[0])}
                            max={2023}
                            step={1}
                            valueLabelDisplay="on"
                            onChange={handleSliderChange}
                            style={{width: "95%"}}
                    />
                </Box>
                <Box className={classes.rank}>
                    <FormControl sx={{m: 1, minWidth: 250, maxWidth: 250}} size="small" fullWidth>
                        <InputLabel id="crime-type-select">{t("rank-method")}</InputLabel>
                        <Select
                            labelId="crime-type-select-label"
                            id="demo-select-small"
                            value={crimeRankType}
                            label={t("crime-type")}
                            onChange={(e) => {
                                setCrimeRankType(e.target.value)
                            }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        width: 250,
                                    },
                                },
                            }}
                        >
                            {
                                [{"value": "metro_rate", "label": t("metro-crime-rate")}, {
                                    "value": "metro_count",
                                    "label": t("metro-crime-count")
                                }, {"value": "state", "label": t("state-crime-count")}].map((obj) => {
                                    return <MenuItem value={obj.value}
                                                     key={obj.value}>{obj.label}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Box className={classes.crimeTypes}>
                    <FormControl sx={{m: 1, minWidth: 250, maxWidth: 250}} size="small" fullWidth>
                        <InputLabel id="crime-type-select">{t("crime-type")}</InputLabel>
                        <Select
                            labelId="crime-type-select-label"
                            id="demo-select-small"
                            value={crimeType}
                            label={t("crime-type")}
                            onChange={handleCrimeTypeChange}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        width: 250,
                                    },
                                },
                            }}
                        >
                            {
                                CRIME_TYPES.map((crimeType) => {
                                    return <MenuItem value={crimeType}
                                                     key={crimeType}>{t(crimeType)}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Typography variant="body2" component="p" gutterBottom className={classes.title}>
                    {t("wa-crime-map-for")} {visibleCrimeYear} <br/> &copy;findperth.com
                </Typography>
                <div
                    id="bottom-right-legend"
                    className={classes.legend}

                >
                    <div className={classes.legendTitle}>{t("Good")}</div>
                    <div className={classes.legendItemColorBar}>
                        <Paper className={crimeRankType === 'state' ? classes.stateColorBar : classes.colorBar}/>
                    </div>
                    <div>{t("Bad")}</div>
                </div>
            </>
        }

            <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            {selectedCrimeProperties?.suburb}: {t(crimeType)}
                        </Grid>
                        <Grid item>
                            <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                <InputLabel id="year-select-label">Year</InputLabel>
                                <Select
                                    labelId="year-select-label"
                                    id="year-select"
                                    value={visibleCrimeYear}
                                    onChange={(e) => {
                                        setVisibleCrimeYear(e.target.value)
                                    }}
                                    label={t("Year")}
                                    disabled
                                >
                                    {[visibleCrimeYear].map((year) => (
                                        <MenuItem key={year} value={year}>{year}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {/* First Row */}
                        <Grid item container xs={12}>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="body1">{t("Count")}: {selectedCrimeProperties?.[`${visibleCrimeYear}.${crimeType}.TotalAnnual`]}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="body2">{t("State Area Count Rank")}: {selectedCrimeProperties?.[`${visibleCrimeYear}.${crimeType}.TotalAnnual_Rank`]}/1751</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="body2">{t("Metro Area Count Rank")}: {selectedCrimeProperties?.[`${visibleCrimeYear}.${crimeType}.TotalAnnual_MetroRank`]}/347</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="body2">{t("Metro Area Rate Rank")}: {selectedCrimeProperties?.[`${visibleCrimeYear}.${crimeType}.TotalAnnual_MetroRateRank`]}/347</Typography>
                            </Grid>
                        </Grid>
                        {/* Second Row */}
                        <Grid item container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                {/*also need show x label*/}
                                {userRole === "unregistered_user" ? <>
                                        {/*add register to view line chart for all years*/}
                                        <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                            <LockIcon/>
                                            <Typography
                                                variant="subtitle1">{t("Register to unlock more data and functions.")} <Link
                                                to="/register">{t("Register")}</Link></Typography>
                                        </div>
                                    </> :
                                    <Line data={prepareSelectedCrimeYearlyData(selectedCrimeYearly)}
                                          options={{
                                              responsive: true,
                                              maintainAspectRatio: true,
                                              plugins: {},
                                              scales: {
                                                  x: {
                                                      title: {
                                                          display: true,
                                                          text: t("Year")
                                                      }
                                                  },
                                                  y: {
                                                      title: {
                                                          display: true,
                                                          text: t("Count")
                                                      }
                                                  }
                                              }
                                          }}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">{t("Crime Distribution(Pie chart)")}</Typography>
                                <Pie data={getSelectedCrimeAnnual()} options={{
                                    plugins: {
                                        legend: {
                                            display: false // This will hide the legend
                                        }
                                    },
                                    // Add any other options you need here
                                }
                                }/>
                            </Grid>

                            {!isMobileOnly && <Grid item xs={12}>
                                <Typography variant="subtitle1">{t("Crime Distribution(Bar chart)")}</Typography>
                                <Bar data={getSelectedCrimeAnnual()} options={{
                                    indexAxis: 'y' as const,
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false // This will hide the legend
                                        }
                                    },
                                }}/>
                            </Grid>}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Button
                className={classes.showFunction}
                fullWidth
                variant="contained"
                startIcon={showControls ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                onClick={toggleControls}
            >
                {showControls ? t('Hide options') : t('Show options')}
            </Button>
            <div
                ref={(el) => {
                    if (el) {
                        // @ts-ignore
                        mapContainer.current = el
                    }
                }}
                className={classes.root}
            />
        </>
    )
}


export default CrimeMap