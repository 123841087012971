// @ts-nocheck
// @ts-nocheck
import React, {useEffect, useRef, useState} from "react";
import mapboxgl from "mapbox-gl";
// import {isMobileOnly} from "react-device-detect";
import {useQuery, useSubscription} from "@apollo/client";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {Link} from 'react-router-dom';
import {
    Paper,
    Box,
    Button,
    Tooltip,
    Typography,
    TextField, Dialog, DialogTitle, DialogContent, Grid
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"
import {useTranslation} from "react-i18next"
import {makeStyles} from "@material-ui/core/styles";
import {WA_SUBURBS, GET_SUBURB} from "../../gql/get_reports"


export const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '64px', // Height of the AppBar
        left: '0',
        right: '0',
        bottom: '0',
    },
    showFunction: {
        position: "absolute",
        right: "0",
        top: "64px",
        zIndex: 999,
        width: "300px",
        height: "20px",
    },
    searchBar: {
        position: "absolute",
        width: "280px",
        height: "50px",
        top: "84px",
        right: "0",
        zIndex: 999,
        backgroundColor: "white",
        borderRadius: "3px",
        padding: "10px",
        border: "10px"
    },
    rank: {
        position: "absolute",
        width: "280px",
        height: "40px",
        top: "144px",
        right: "0",
        zIndex: 998,
        backgroundColor: "white",
        padding: "10px",
        border: "10px"
    },


}));


const SchoolMap: React.FC = () => {
    const classes = useStyles();
    const [map, setMap] = useState(null)
    const mapContainer = useRef(null)
    const {t} = useTranslation();
    const [schoolClassification, setSchoolClassification] = useState("ALL")
    const [selectedSchoolId, setSelectedSchoolId] = useState(null)
    const [metroSuburbs, setMetroSuburbs] = useState([])
    const [selectedSuburb, setSelectedSuburb] = useState(null)
    const [selectedMapSuburb, setSelectedMapSuburb] = useState(null);
    const [showControls, setShowControls] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(null);

    const handleClose = () => {
        setOpenDialog(false);
    };
    const toggleControls = () => {
        setShowControls(!showControls);
    };
    // select suburb functions
    const {data: metroSuburbsData} = useQuery(
        WA_SUBURBS
    )
    // update suburb options
    useEffect(() => {
        if (metroSuburbsData) {
            setMetroSuburbs(
                metroSuburbsData?.abs_abssuburbgeo
            )
        }
    }, [metroSuburbsData, setMetroSuburbs]);
    const {data: suburbData, refetch: suburbRefetch} = useQuery(GET_SUBURB, {
        variables: {
            suburbName: selectedSuburb
        }
    })
    useEffect(() => {
        const fetchSuburb = async () => {
            if (selectedSuburb && map) {
                try {
                    const result = await suburbRefetch({
                        variables: {
                            suburbName: selectedSuburb
                        }
                    });

                    //@ts-ignore
                    const moveToPolygon = (polygon) => {
                        // @ts-ignore
                        const bounds = new mapboxgl.LngLatBounds();
                        // @ts-ignore
                        polygon.forEach((point) => {
                            bounds.extend(point);
                        });
                        // @ts-ignore
                        map.fitBounds(bounds, {
                            padding: 30,
                            maxZoom: 12
                        });

                    };
                    //@ts-ignore
                    map.getSource('selectedSuburbPolygon').setData({
                        'type': 'Feature',
                        'geometry': result?.data?.abs_abssuburbgeo?.[0]?.geometry,
                        'properties': {
                            'name': selectedSuburb // The text to display on the polygon
                        }
                    });
                    moveToPolygon(result?.data?.abs_abssuburbgeo?.[0]?.geometry?.coordinates)
                } catch (error) {
                    console.error("Error refetching:", error);
                }
            }
        };

        fetchSuburb();
    }, [selectedSuburb, map]);

    // init the map
    useEffect(() => {
        let ignore = false
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY

        // @ts-ignore
        const initializeMap = ({setMap, mapContainer}) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/pascalsun/ckq0th5wt25e117p8smraill2", // style URL
                center: [115.8142283, -31.9810844],
                zoom: 10, // starting zoom
                minZoom: 4,
            })
            map.addControl(new mapboxgl.NavigationControl(), "bottom-left")
            map.on("load", () => {
                if (!ignore) setMap(map)
            })
        }
        if (!map) initializeMap({setMap, mapContainer})
        return () => {
            ignore = true
        }
    }, [map])

    // init the data layers on the map
    useEffect(() => {
        if (map) {
            // @ts-ignore
            map?.resize()

            // add school intake layer
            // @ts-ignore
            const schoolLayer = map.getLayer(`schoolLayer`)

            if (!schoolLayer) {
                // @ts-ignore
                map.addSource(`schoolTileset`, {
                    type: 'vector',
                    // @ts-ignore
                    url: `mapbox://pascalsun.7ae5xioh`
                });

                // @ts-ignore
                map.addLayer({
                    id: "schoolLayer",
                    type: "circle",
                    source: "schoolTileset",
                    // @ts-ignore
                    "source-layer": "school_with_contact_details-biuqxs",
                    paint: {
                        "circle-radius": 10,
                        "circle-color": [
                            "match",
                            ["get", "classification_group"],
                            "PRIMARY SCHOOLS",
                            "#87CEEB",
                            "SECONDARY SCHOOLS",
                            "#000080",
                            "EDUCATION SUPPORT",
                            "#D8BFD8",
                            "DISTRICT HIGH SCHOOLS",
                            "#FFD700",
                            "SPECIALIST SCHOOLS",
                            "#800080",
                            "COMMUNITY KINDERGARTENS",
                            "#FFC0CB",
                            "K-12 SCHOOLS",
                            "#FF0000",
                            /* other */ "#ccc"
                        ],
                        "circle-opacity": 0.8
                    }
                })
            }

            // @ts-ignore
            const schoolIntakeLayer = map.getLayer(`schoolIntakeLayer`)

            if (!schoolIntakeLayer) {
                // @ts-ignore
                map.addSource(`schoolIntakeTileset`, {
                    type: 'vector',
                    // @ts-ignore
                    url: `mapbox://pascalsun.b11kaaz4`
                });

                // @ts-ignore
                map.addLayer({
                    id: "schoolIntakeLayer",
                    type: "fill",
                    source: "schoolIntakeTileset",
                    // @ts-ignore
                    "source-layer": "intake-9yg1d8",
                    paint: {
                        "fill-color": [
                            "match",
                            ["get", "classification_group"],
                            "PRIMARY SCHOOLS",
                            [
                                "match",
                                ["get", "intakeType"],
                                "MAIN",
                                "#87CEEB",
                                "black"
                            ],

                            "SECONDARY SCHOOLS",
                            [
                                "match",
                                ["get", "intakeType"],
                                "MAIN",
                                "#000080",
                                "black"
                            ],

                            "EDUCATION SUPPORT",
                            [
                                "match",
                                ["get", "intakeType"],
                                "MAIN",
                                "#D8BFD8",
                                "black"
                            ],
                            "DISTRICT HIGH SCHOOLS",
                            [
                                "match",
                                ["get", "intakeType"],
                                "MAIN",
                                "#FFD700",
                                "black"
                            ],

                            "SPECIALIST SCHOOLS",
                            [
                                "match",
                                ["get", "intakeType"],
                                "MAIN",
                                "#800080",
                                "black"
                            ],

                            "COMMUNITY KINDERGARTENS",
                            [
                                "match",
                                ["get", "intakeType"],
                                "MAIN",
                                "#FFC0CB",
                                "black"
                            ],

                            "K-12 SCHOOLS",
                            [
                                "match",
                                ["get", "intakeType"],
                                "MAIN",
                                "#FF0000",
                                "black"
                            ],
                            /* other */ "#ccc"
                        ],
                        "fill-opacity": 0.8
                    }
                })
            }

            // @ts-ignore
            map.on("click", `schoolLayer`, (e: { features: { properties: { [x: string]: any } }[] }) => {
                setSelectedSchoolId(e.features[0].properties?.school_id)
                setSelectedSchool(e.features[0].properties)
                setOpenDialog(true);
                if (window.location.hostname !== "localhost") {
                    ReactGA.event({
                        category: "User",
                        action: 'Click',
                        label: `${e.features[0].properties?.school_name}`
                    });
                }
            })
            // add selected suburb layer
            //@ts-ignore
            const selectedSuburbPolygonLayer = map?.getLayer(`selectedSuburbPolygonLayer`)
            if (!selectedSuburbPolygonLayer) {
                // @ts-ignore
                map.addSource('selectedSuburbPolygon', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': [[]], // Empty coordinates
                        }
                    }
                });
                //@ts-ignore
                map.addLayer({
                    'id': 'selectedSuburbPolygonLayer',
                    'type': 'line',
                    'source': 'selectedSuburbPolygon',
                    'layout': {},
                    'paint': {
                        'line-color': '#000000',  // Border color of the polygon
                        'line-width': 5,           // Width of the polygon border
                    }
                });
                // @ts-ignore
                map.addLayer({
                    'id': 'selectedSuburbPolygonTextLayer',
                    'type': 'symbol',
                    'source': 'selectedSuburbPolygon',
                    'layout': {
                        'text-field': '{name}', // Assuming the feature property "name" has the text you want to display
                        'text-anchor': 'center',

                    },
                    "minzoom": 11,
                    'paint': {
                        'text-color': '#00008B' // Dark blue color
                    },
                });
            }
        }
    }, [
        map,
    ])
    useEffect(() => {
        if (map) {
            // @ts-ignore
            const layer = map.getLayer(`schoolIntakeLayer`)
            if (layer) {
                // @ts-ignore
                map?.setFilter(`schoolIntakeLayer`, ["==", "school_id", selectedSchoolId || ""])
            }
        }
    }, [selectedSchoolId, map])

    // school
    useEffect(() => {
        if (map) {
            // @ts-ignore
            const layer = map.getLayer(`schoolLayer`)
            if (layer) {
                if (schoolClassification === "ALL") {
                    // @ts-ignore
                    map?.setFilter(`schoolLayer`, ["has", "classification_group"])
                } else {
                    // @ts-ignore
                    map?.setFilter(`schoolLayer`, ["==", "classification_group", schoolClassification])
                }

            }
        }
    }, [schoolClassification, map])

    return (
        <>{
            showControls && <>
                <Autocomplete
                    className={classes.searchBar}
                    freeSolo
                    // @ts-ignore
                    options={metroSuburbs?.filter(suburb => suburb?.sal_name21).map(suburb => suburb.sal_name21)}
                    onChange={(event, newValue) => {
                        setSelectedSuburb(newValue);
                    }}
                    value={selectedSuburb}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("Search suburb")}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                style: {
                                    height: "50px",
                                    padding: "10px",
                                    border: "10px",
                                    width: "250px",
                                    alignItems: "center"
                                } // this adjusts the input height and padding inside
                            }}
                            InputLabelProps={{
                                style: {
                                    marginLeft: "20px" // adjust this value as needed
                                }
                            }}
                            style={{
                                margin: 0,
                                alignItems: "center"
                            }} // to ensure the label and input are correctly aligned
                        />
                    )}
                />

                <Box className={classes.rank}>
                    <FormControl sx={{m: 1, minWidth: 250, maxWidth: 250}} size="small" fullWidth>
                        <InputLabel id="crime-type-select">{t("School Classification")}</InputLabel>
                        <Select
                            labelId="crime-type-select-label"
                            id="demo-select-small"
                            value={schoolClassification}
                            label={t("School Classification")}
                            onChange={(e) => {
                                setSchoolClassification(e.target.value)
                            }}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        width: 250,
                                    },
                                },
                            }}
                        >
                            {
                                [{"value": "PRIMARY SCHOOLS", "label": "PRIMARY SCHOOLS", "color": "#87CEEB"},
                                    {"value": "SECONDARY SCHOOLS", "label": "SECONDARY SCHOOLS", "color": "#000080"},
                                    {"value": "EDUCATION SUPPORT", "label": "EDUCATION SUPPORT", "color": "#D8BFD8"},
                                    {
                                        "value": "DISTRICT HIGH SCHOOLS",
                                        "label": "DISTRICT HIGH SCHOOLS",
                                        "color": "#FFD700"
                                    },
                                    {"value": "SPECIALIST SERVICES", "label": "SPECIALIST SCHOOLS", "color": "#800080"},
                                    {
                                        "value": "COMMUNITY KINDERGARTENS",
                                        "label": "COMMUNITY KINDERGARTENS",
                                        "color": "#FFC0CB"
                                    },
                                    {"value": "K-12 SCHOOLS", "label": "K-12 SCHOOLS", "color": "#FF0000"},
                                    {"value": "ALL", "label": "ALL", "color": "grey"},
                                ].map((obj) => {
                                    return <MenuItem value={obj.value}
                                                     style={{backgroundColor: obj.color, color: "white"}}
                                                     key={obj.value}>{t(obj.label)}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>

            </>
        } <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>{selectedSchool?.school_name}</DialogTitle>
            <DialogContent>

                <Grid container spacing={2}>
                    {/* First Row */}
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="body1">{t("School Type")}: {selectedSchool?.classification_group}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="body1">{t("School Year From")}: {selectedSchool?.school_year_from}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="body1">{t("School Year To")}: {selectedSchool?.schoold_year_to}</Typography>
                        </Grid>
                        <Grid>
                            <Typography
                                variant={"body1"}>{t("Established date")}: {selectedSchool?.established_date}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body2">{t("Website")}: <Link
                                to={selectedSchool?.["Website "]} target="_blank">{selectedSchool?.["Website "]}</Link></Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="body2">{t("Address")}: {selectedSchool?.["Physical Address"]}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="body2">{t("Intake Map")}: <Link
                                to={`https://www.det.wa.edu.au/schoolsonline/${selectedSchool?.["map_pdf_url"]}`}
                                target="_blank">{t("Click to view")}</Link></Typography>
                        </Grid>
                    </Grid>

                </Grid>
            </DialogContent>
        </Dialog>
            <Button
                className={classes.showFunction}
                fullWidth
                variant="contained"
                startIcon={showControls ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                onClick={toggleControls}
            >
                {showControls ? t('Hide options') : t('Show options')}
            </Button>
            <div
                ref={(el) => {
                    if (el) {
                        // @ts-ignore
                        mapContainer.current = el
                    }
                }}
                className={classes.root}

            />
        </>
    )

}

export default SchoolMap