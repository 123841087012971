// @ts-nocheck
import React, {useEffect, useRef, useState} from "react";
import mapboxgl from "mapbox-gl";
// import {isMobileOnly} from "react-device-detect";
import {useQuery, useSubscription} from "@apollo/client";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
    Paper,
    Box,
    Button,
    Tooltip,
    Typography,
    TextField, Dialog, DialogTitle, DialogContent, Grid
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"
import {useTranslation} from "react-i18next"
import {makeStyles} from "@material-ui/core/styles";
import {GET_REPORT, SUB_REPORTS, WA_SUBURBS, GET_SUBURB} from "../../gql/get_reports"
import {Chart as ChartJS, ArcElement, Legend, CategoryScale, BarElement, Title,} from 'chart.js';
import {Bar} from 'react-chartjs-2';

const verticalLinePlugin = {
    id: 'verticalLine',
    afterDraw: (chart, args, options) => {
        if (options.lineAtIndex) {
            const ctx = chart.ctx;
            const x = chart.getDatasetMeta(0).data[options.lineAtIndex].x;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, chart.chartArea.top);
            ctx.lineTo(x, chart.chartArea.bottom);
            ctx.lineWidth = options.lineWidth || 2;
            ctx.strokeStyle = options.lineColor || 'red';
            ctx.stroke();
            ctx.restore();
        }
    }
};
ChartJS.register(ArcElement, Legend, CategoryScale,
    BarElement,
    Title, verticalLinePlugin);

export const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '64px', // Height of the AppBar
        left: '0',
        right: '0',
        bottom: '0',
    },
    showFunction: {
        position: "absolute",
        right: "0",
        top: "64px",
        zIndex: 999,
        width: "300px",
        height: "20px",
    },
    searchBar: {
        position: "absolute",
        width: "280px",
        height: "50px",
        top: "84px",
        right: "0",
        zIndex: 999,
        backgroundColor: "white",
        borderRadius: "3px",
        padding: "10px",
        border: "10px"
    },
    rank: {
        position: "absolute",
        width: "280px",
        height: "40px",
        top: "144px",
        right: "0",
        zIndex: 998,
        backgroundColor: "white",
        padding: "10px",
        border: "10px"
    },
    legend: {
        position: 'absolute',
        top: '204px',
        right: '0px',
        display: 'flex',
        flexDirection: 'row', // 横向布局
        justifyContent: 'center',
        alignItems: 'center',
        padding: "10px",
        backgroundColor: 'white',
        zIndex: 999,
        width: "280px"
    },
    legendTitle: {
        marginRight: theme.spacing(1), // 与颜色条之间的距离
    },
    legendItemColorBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    colorBar: {
        width: "200px", // 或根据需要调整
        height: 10,
        background: 'linear-gradient(to right, rgba(116,211,251, 1), rgba(158, 1, 66, 1))',
        marginRight: theme.spacing(1),
    },
}));


const ABS2021: React.FC = () => {
    const classes = useStyles();
    const [map, setMap] = useState(null)
    const mapContainer = useRef(null)
    const {t} = useTranslation();
    const [dynamicData, setDynamicData] = useState("")
    const [reports, setReports] = useState([])
    const [selectedReport, setSelectedReport] = useState("")
    const [metroSuburbs, setMetroSuburbs] = useState([])
    const [selectedSuburb, setSelectedSuburb] = useState("")
    const [selectedMapSuburb, setSelectedMapSuburb] = useState("");
    const [showControls, setShowControls] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const handleClose = () => {
        setOpenDialog(false);
    };

    // select suburb functions
    const {data: metroSuburbsData} = useQuery(
        WA_SUBURBS
    )
    // update suburb options
    useEffect(() => {
        if (metroSuburbsData) {
            setMetroSuburbs(
                metroSuburbsData?.abs_abssuburbgeo
            )
        }
    }, [metroSuburbsData, setMetroSuburbs]);
    const {data: suburbData, refetch: suburbRefetch} = useQuery(GET_SUBURB, {
        variables: {
            suburbName: selectedSuburb
        }
    })
    useEffect(() => {
        const fetchSuburb = async () => {
            if (selectedSuburb && map) {
                try {
                    const result = await suburbRefetch({
                        variables: {
                            suburbName: selectedSuburb
                        }
                    });

                    //@ts-ignore
                    const moveToPolygon = (polygon) => {
                        // @ts-ignore
                        const bounds = new mapboxgl.LngLatBounds();
                        // @ts-ignore
                        polygon.forEach((point) => {
                            bounds.extend(point);
                        });
                        // @ts-ignore
                        map.fitBounds(bounds, {
                            padding: 30,
                            maxZoom: 12
                        });

                    };
                    //@ts-ignore
                    map.getSource('selectedSuburbPolygon').setData({
                        'type': 'Feature',
                        'geometry': result?.data?.abs_abssuburbgeo?.[0]?.geometry,
                        'properties': {
                            'name': selectedSuburb // The text to display on the polygon
                        }
                    });
                    moveToPolygon(result?.data?.abs_abssuburbgeo?.[0]?.geometry?.coordinates)
                } catch (error) {
                    console.error("Error refetching:", error);
                }
            }
        };

        fetchSuburb();
    }, [selectedSuburb, map]);

    const {data: reportsData} = useSubscription(SUB_REPORTS)
    useEffect(() => {
        if (reportsData) {
            setReports(reportsData?.hasura_reports)
            // @ts-ignore
            setSelectedReport(reportsData?.hasura_reports?.[0]?.name)
        }
    }, [reportsData, setReports, setSelectedReport]);

    const getDynamicMetroHtml = (suburbName: string) => {
        return `<div>
                    <h3>${suburbName}</h3>
                    <p>Value: ${dynamicData?.[suburbName] === -1 ? "No data" : dynamicData?.[suburbName]}</p>
               </div>
               `
    }

    // get from server for specific reports
    const {data, loading} = useQuery(GET_REPORT, {
        variables: {
            reportName: selectedReport || "IndigenousPopulation"
        }
    })

    const toggleControls = () => {
        setShowControls(!showControls);
    };
    const getSuburbProperties = (suburbName: string, return_value: string = "normalized_value") => {
        if (data) {
            // find the suburb name item from the array
            const suburb = data.hasura_reportstoragetable.find((item: {
                suburb: string;
            }) => item.suburb === suburbName);
            if (return_value === "normalized_value") {
                return suburb?.normalized_value.toFixed(2)
            } else {
                return suburb?.value
            }
        }
    }
    // init the map
    useEffect(() => {
        let ignore = false
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY

        // @ts-ignore
        const initializeMap = ({setMap, mapContainer}) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/pascalsun/ckq0th5wt25e117p8smraill2", // style URL
                center: [115.8142283, -31.9810844],
                zoom: 10, // starting zoom
                minZoom: 4,
            })
            map.addControl(new mapboxgl.NavigationControl(), "bottom-left")
            map.on("load", () => {
                if (!ignore) setMap(map)
            })
        }
        if (!map) initializeMap({setMap, mapContainer})
        return () => {
            ignore = true
        }
    }, [map])

    // init the data layers on the map
    useEffect(() => {
        if (map) {
            // @ts-ignore
            map?.resize()
            // add dynamic metro layer
            // @ts-ignore
            const dynamicMetro = map.getLayer(`dynamicMetro`)
            if (!dynamicMetro) {
                // @ts-ignore
                map.addSource(`dynamicPerthmetroTileset`, {
                    type: 'vector',
                    // @ts-ignore
                    url: `mapbox://pascalsun.4upov0fi`
                });

                // circle style
                // @ts-ignore
                map.addLayer({
                    id: `dynamicMetro`,
                    type: 'fill',
                    source: `dynamicPerthmetroTileset`,
                    // @ts-ignore
                    "source-layer": "perth_metro",
                    "paint": {
                        "fill-color": [
                            "interpolate",
                            ["linear"],
                            ["get", `normalized_value`],
                            -1,
                            'rgba(256, 256, 256, 1)',
                            0,
                            "rgba(116,211,251, 1)",
                            1,
                            'rgba(195, 255, 104, 1)',
                        ],
                        "fill-opacity": 0.8
                    }
                });
            }

            // set onClick event
            // @ts-ignore
            map.on("click", `dynamicMetro`, (e: { features: { properties: { [x: string]: any } }[] }) => {
                // new mapboxgl.Popup({
                //     closeButton: false,
                //     closeOnClick: true,
                //     closeOnMove: true,
                // })
                //     // @ts-ignore
                //     .setLngLat(e.lngLat)
                //     .setHTML(getDynamicMetroHtml(e.features[0].properties?.sal_name21))
                //     .addTo(map);
                setOpenDialog(true)
                setSelectedMapSuburb(e.features[0].properties?.sal_name21)
            }) // add selected suburb layer
            //@ts-ignore
            const selectedSuburbPolygonLayer = map?.getLayer(`selectedSuburbPolygonLayer`)
            if (!selectedSuburbPolygonLayer) {
                // @ts-ignore
                map.addSource('selectedSuburbPolygon', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': [[]], // Empty coordinates
                        }
                    }
                });
                //@ts-ignore
                map.addLayer({
                    'id': 'selectedSuburbPolygonLayer',
                    'type': 'line',
                    'source': 'selectedSuburbPolygon',
                    'layout': {},
                    'paint': {
                        'line-color': '#000000',  // Border color of the polygon
                        'line-width': 5,           // Width of the polygon border
                    }
                });
                // @ts-ignore
                map.addLayer({
                    'id': 'selectedSuburbPolygonTextLayer',
                    'type': 'symbol',
                    'source': 'selectedSuburbPolygon',
                    'layout': {
                        'text-field': '{name}', // Assuming the feature property "name" has the text you want to display
                        'text-anchor': 'center',

                    },
                    "minzoom": 11,
                    'paint': {
                        'text-color': '#00008B' // Dark blue color
                    },
                });
            }
        }
    }, [
        map,

        getDynamicMetroHtml
    ])
    // dynamic metro map, when the layer changes to the dynamic one
    // then we will query the gql and setData for the layer
    useEffect(() => {
        if (map) {
            if (data) {
                const suburbValues: { [key: string]: number } = {};
                data.hasura_reportstoragetable.forEach((item: { suburb: string }) => {
                    suburbValues[item.suburb] = getSuburbProperties(item.suburb);
                });
                // @ts-ignore
                map?.setPaintProperty(`dynamicMetro`, 'fill-color', [
                    'interpolate',
                    ['linear'],
                    ['to-number',
                        ['case',
                            ['has', ['get', 'sal_name21'], ['literal', suburbValues]], // Check if the suburb exists in the mapping
                            ['get', ['get', 'sal_name21'], ['literal', suburbValues]], // Get the numeric value
                            -1 // Default value if suburb doesn't exist in the mapping
                        ]
                    ],
                    -1,
                    'rgba(256, 256, 256, 1)',
                    0, "rgba(116,211,251, 1)",  // For value 0, use blue
                    1, 'rgba(158, 1, 66, 1)' // For value 100, use red
                ])
            }
        }
    }, [map, data, loading])


    useEffect(() => {
        if (data) {
            const suburbShowValue: { [key: string]: number } = {};
            data.hasura_reportstoragetable.forEach((item: { suburb: string }) => {
                suburbShowValue[item.suburb] = getSuburbProperties(item.suburb, "value");
            });
            // @ts-ignore
            setDynamicData(suburbShowValue)
        }
    }, [data, setDynamicData, selectedReport])

    const getChartData = () => {
        if (data) {
            const values = data.hasura_reportstoragetable.map((item) => {
                return item?.value
            })
            const labels = data.hasura_reportstoragetable.map((item) => {
                return item?.suburb
            })

            return {
                labels: labels,
                datasets: [
                    {
                        label: 'Count',
                        data: values,
                        backgroundColor: labels.map((item: string) => {
                            item === selectedMapSuburb ? "rgba(158, 1, 66, 1)" : "rgba(116,211,251, 1)"
                        }),
                        borderWidth: 1,
                    },
                ],
            }
        }
    }

    const getSuburbIndex = (suburbName: string) => {
        if (data) {
            return data.hasura_reportstoragetable.findIndex((item: { suburb: string }) => item.suburb === suburbName)
        }
    }


    return (
        <>{
            showControls && <>
                <Autocomplete
                    className={classes.searchBar}
                    freeSolo
                    // @ts-ignore
                    options={metroSuburbs?.filter(suburb => suburb?.sal_name21).map(suburb => suburb.sal_name21)}
                    onChange={(event, newValue) => {
                        setSelectedSuburb(newValue);
                    }}
                    value={selectedSuburb}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("Search suburb")}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                style: {
                                    height: "50px",
                                    padding: "10px",
                                    border: "10px",
                                    width: "250px",
                                    alignItems: "center"
                                } // this adjusts the input height and padding inside
                            }}
                            InputLabelProps={{
                                style: {
                                    marginLeft: "20px" // adjust this value as needed
                                }
                            }}
                            style={{
                                margin: 0,
                                alignItems: "center"
                            }} // to ensure the label and input are correctly aligned
                        />
                    )}
                />

                <Box className={classes.rank}>
                    <FormControl sx={{m: 1, minWidth: 250, maxWidth: 250}} size="small" fullWidth>
                        <InputLabel
                            id="report-name"
                        >{(t("Report Name"))}</InputLabel>
                        <Tooltip
                            title={reportsData?.hasura_reports?.find((report: any) => report?.name == selectedReport)?.description || ""}
                            placement="top"
                        >
                            <Select
                                labelId="immi-country-rank-label"
                                id="immi-country-rank-select"
                                value={selectedReport}

                                // @ts-ignore
                                onChange={e => setSelectedReport(e.target.value)}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            width: 250,
                                        },
                                    },
                                }}
                            >
                                {
                                    reports.map((obj: any) => {
                                        return <MenuItem value={obj?.name}
                                                         key={obj?.name}>
                                            {obj.user_report_name || obj.name}
                                        </MenuItem>
                                    })
                                }
                            </Select>
                        </Tooltip>
                    </FormControl>

                </Box>

                <div
                    id="bottom-right-legend"
                    className={classes.legend}

                >
                    <div className={classes.legendTitle}>{t("Less")}</div>
                    <div className={classes.legendItemColorBar}>
                        <Paper className={classes.colorBar}/>
                    </div>
                    <div>{t("More")}</div>
                </div>
            </>
        }
            <Dialog open={openDialog && (selectedReport !== "")} onClose={handleClose}>
                <DialogTitle>{selectedMapSuburb}</DialogTitle>
                <DialogContent>

                    <Grid container spacing={2}>
                        {/* First Row */}
                        <Grid item container xs={12}>
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    variant="body1">{t("Selected report")}: {reportsData?.hasura_reports?.find((report: any) => report?.name == selectedReport)?.user_report_name || ""}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="body1">{t("Absolute Count")}: {getSuburbProperties(selectedMapSuburb, "value")}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    variant="body1">{t("Normalized Ratio")}: {getSuburbProperties(selectedMapSuburb)}/1</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    variant="body1">{t("Report Description")}: {reportsData?.hasura_reports?.find((report: any) => report?.name == selectedReport)?.description || ""}</Typography>
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} sm={12}>
                            <Bar
                                data={getChartData()}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        y: {
                                            beginAtZero: true,
                                            min: 0,
                                        }
                                    },
                                    plugins: {
                                        legend: {
                                            display: false
                                        },
                                        title: {
                                            display: false,
                                        },
                                        verticalLine: {
                                            lineAtIndex: getSuburbIndex(selectedMapSuburb), // The index of the value on x axis that the line will be drawn at
                                            lineColor: 'rgba(255, 0, 0, 0.5)', // Custom line color
                                            lineWidth: 10, // Custom line width
                                        }

                                    }
                                }}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
            </Dialog>
            <Button
                className={classes.showFunction}
                fullWidth
                variant="contained"
                startIcon={showControls ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                onClick={toggleControls}
            >
                {showControls ? t('Hide options') : t('Show options')}
            </Button>
            <div
                ref={(el) => {
                    if (el) {
                        // @ts-ignore
                        mapContainer.current = el
                    }
                }}
                className={classes.root}

            />
        </>
    )

}

export default ABS2021