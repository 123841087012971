// @ts-nocheck
import React from 'react'
import ReactDOM from 'react-dom/client'
import {composeWithDevTools} from 'redux-devtools-extension'
import * as Sentry from "@sentry/react"
import {Provider} from "react-redux"
import {createStore} from "redux"

import {ApolloProvider} from '@apollo/client';
import IndexReducer from "./index-reducer"
import client from "./gql/client";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translationsZH from './locales/zh.json';
import translationsEN from './locales/en.json';
import ReactGA from 'react-ga';
import {ThemeProvider} from '@mui/material/styles';
import {createTheme} from '@mui/material/styles';

const browserLanguage = navigator.language.split('-')[0]

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationsEN,
            },
            zh: {
                translation: translationsZH,
            },
        },
        lng: browserLanguage, // Set the default language
        fallbackLng: 'en', // Fallback language if translation is missing
        interpolation: {
            escapeValue: false, // React already escapes values
        },
    });


// sentry
Sentry.init({
    dsn: "https://ab924e4226a4492cb23f81b87b771d84@o375796.ingest.sentry.io/4504973610254336",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// google analytics
ReactGA.initialize('G-PHX7M2TN7Q');

const store = createStore(IndexReducer, composeWithDevTools(
    // @ts-ignore
))

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


const theme = createTheme({
    palette: {
        primary: {
            main: '#6ac2ee',
        },
        // ... include other color overrides if necessary
    },
    // ... include other theme configurations if necessary
});


root.render(
    <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
            <Provider store={store}>
                <Router>
                    <App/>
                </Router>
            </Provider>
        </ApolloProvider>
    </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
