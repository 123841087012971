// @ts-nocheck
import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import './App.css';
import NavBar from "./components/NavBar/NavBar";
import RegistrationPage from "./pages/Auth/Register";
import LoginPage from "./pages/Auth/Login";
import ABS2021 from "./pages/Map/ABS2021";
import CrimeMap from "./pages/Map/CrimeMap";
import SchoolMap from "./pages/Map/SchoolMap";
import CountryBirthMap from "./pages/Map/CountryBirthMap";
import ChatPage from "./pages/Chat/Chat";
import {refreshToken} from "./utils/refreshToken";
import Suburb from "./pages/Suburb/Suburb";


function App() {
    useEffect(() => {
        const interval = setInterval(() => {
            refreshToken();
        }, 1000 * 60 * 3);

        return () => clearInterval(interval)
    }, []);

    return (
        <div className="App">
            <NavBar/>
            <Routes>
                <Route path="/" element={<CrimeMap/>}/>
                <Route path="/abs2021" element={<ABS2021/>}/>
                <Route path="/school" element={<SchoolMap/>}/>
                <Route path="/register" element={<RegistrationPage/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/crime" element={<CrimeMap/>}/>
                <Route path="/countrybirth" element={<CountryBirthMap/>}/>
                {/*    suburb path should also include param suburb name*/}
                <Route path="/suburb/:suburbName" element={<Suburb/>}/>
                <Route path="/chat" element={<ChatPage/>}/>
            </Routes>
        </div>
    );
}

export default App;