// @ts-nocheck
import React from 'react';
import {AppBar, Toolbar, IconButton, Button, Box, Menu, MenuItem, ListItemIcon, ListItemText} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import LanguageSelector from '../LanguageSelector/LanguageSelector'; // Import your LanguageSelector component
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux"
import {logout} from "../../pages/Auth/reducer";
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // Profile icon
import SideBar from "../SideBar/SideBar";
import {toggleDrawer} from "../../redux/ui";

const NavBar: React.FC = () => {
    const [t] = useTranslation()
    // select is login
    const dispatch = useDispatch()
    const isLogin = useSelector(state => state?.login?.isLogin || false)

    const userProfile = useSelector(
        state => state?.login
    )
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        dispatch(logout());
    };

    const toggleDrawerOpen = () => {
        dispatch(toggleDrawer())
    }


    return (
        <>
            <SideBar/>
            <AppBar position="static">
                <Toolbar>
                    {/* Menu Icon */}
                    <IconButton
                        onClick={toggleDrawerOpen}
                        edge="start"
                        color="white"
                        aria-label="menu"
                        sx={{mr: 2, color: 'white'}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="home"
                        sx={{mr: 1}}
                    >
                        <Link to="/" style={{color: 'white'}}>
                            <HomeIcon/>
                        </Link>
                    </IconButton>

                    {/* Space between */}
                    <Box sx={{flexGrow: 1}}/>
                    {/* Language Selector */}
                    <LanguageSelector/>
                    {
                        isLogin ? (
                                <div>
                                    <IconButton
                                        onClick={handleMenu}
                                        color="white"
                                        sx={{mr: 2, color: 'white'}}
                                        style={{margin: '10px 0'}}
                                    >
                                        <AccountCircleIcon/>
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={handleClose}>
                                            <ListItemText primary={userProfile?.username} secondary={userProfile?.role}/>
                                        </MenuItem>
                                        <MenuItem onClick={handleLogout}>
                                            <ListItemIcon>
                                                <AccountCircleIcon fontSize="small"/>
                                            </ListItemIcon>
                                            <ListItemText primary={t("Logout")}/>
                                        </MenuItem>
                                    </Menu>
                                </div>) :
                            <Link to="/login" style={{textDecoration: 'none', color: 'white'}}>
                                <Button color="inherit">{t("login")}</Button>
                            </Link>
                    }
                </Toolbar>
            </AppBar>
        </>
    );
};

export default NavBar;
