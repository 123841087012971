// @ts-nocheck
import React, {useEffect, useRef, useState} from "react";
import mapboxgl from "mapbox-gl";
// import {isMobileOnly} from "react-device-detect";
import {useQuery, useSubscription} from "@apollo/client";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
    Paper,
    Box,
    Button,
    Tooltip,
    Typography,
    TextField, Dialog, DialogTitle, DialogContent, Grid
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"
import {useTranslation} from "react-i18next"
import {makeStyles} from "@material-ui/core/styles";
import {GET_REPORT, SUB_REPORTS, WA_SUBURBS, GET_SUBURB} from "../../gql/get_reports"
import {Chart as ChartJS, ArcElement, Legend, CategoryScale, BarElement, Title,} from 'chart.js';
import {Bar} from 'react-chartjs-2';

ChartJS.register(ArcElement, Legend, CategoryScale,
    BarElement,
    Title);

export const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: '64px', // Height of the AppBar
        left: '0',
        right: '0',
        bottom: '0',
    },
    showFunction: {
        position: "absolute",
        right: "0",
        top: "64px",
        zIndex: 999,
        width: "300px",
        height: "20px",
    },
    searchBar: {
        position: "absolute",
        width: "280px",
        height: "50px",
        top: "84px",
        right: "0",
        zIndex: 999,
        backgroundColor: "white",
        borderRadius: "3px",
        padding: "10px",
        border: "10px"
    },
    rank: {
        position: "absolute",
        width: "280px",
        height: "40px",
        top: "144px",
        right: "0",
        zIndex: 998,
        backgroundColor: "white",
        padding: "10px",
        border: "10px"
    },
    legend: {
        position: 'absolute',
        top: '204px',
        right: '0px',
        display: 'flex',
        flexDirection: 'row', // 横向布局
        justifyContent: 'center',
        alignItems: 'center',
        padding: "10px",
        backgroundColor: 'white',
        zIndex: 999,
        width: "280px"
    },
    legendTitle: {
        marginRight: theme.spacing(1), // 与颜色条之间的距离
    },
    legendItemColorBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    colorBar: {
        width: "200px", // 或根据需要调整
        height: 10,
        background: 'linear-gradient(to right, rgba(116,211,251, 1), rgba(195, 255, 104, 1))',
        marginRight: theme.spacing(1),
    },
}));


const CountryBirthMap: React.FC = () => {
    const classes = useStyles();
    const [map, setMap] = useState(null)
    const mapContainer = useRef(null)
    const {t} = useTranslation();

    const [metroSuburbs, setMetroSuburbs] = useState([])
    const [selectedSuburb, setSelectedSuburb] = useState(null)
    const [selectedMapSuburb, setSelectedMapSuburb] = useState(null);
    const [showControls, setShowControls] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [immiDistributionRank, setImmiDistributionRank] = useState("1")

    const handleClose = () => {
        setOpenDialog(false);
    };
    const toggleControls = () => {
        setShowControls(!showControls);
    };
    // select suburb functions
    const {data: metroSuburbsData} = useQuery(
        WA_SUBURBS
    )
    // update suburb options
    useEffect(() => {
        if (metroSuburbsData) {
            setMetroSuburbs(
                metroSuburbsData?.abs_abssuburbgeo
            )
        }
    }, [metroSuburbsData, setMetroSuburbs]);
    const {data: suburbData, refetch: suburbRefetch} = useQuery(GET_SUBURB, {
        variables: {
            suburbName: selectedSuburb
        }
    })
    useEffect(() => {
        const fetchSuburb = async () => {
            if (selectedSuburb && map) {
                try {
                    const result = await suburbRefetch({
                        variables: {
                            suburbName: selectedSuburb
                        }
                    });

                    //@ts-ignore
                    const moveToPolygon = (polygon) => {
                        // @ts-ignore
                        const bounds = new mapboxgl.LngLatBounds();
                        // @ts-ignore
                        polygon.forEach((point) => {
                            bounds.extend(point);
                        });
                        // @ts-ignore
                        map.fitBounds(bounds, {
                            padding: 30,
                            maxZoom: 12
                        });

                    };
                    //@ts-ignore
                    map.getSource('selectedSuburbPolygon').setData({
                        'type': 'Feature',
                        'geometry': result?.data?.abs_abssuburbgeo?.[0]?.geometry,
                        'properties': {
                            'name': selectedSuburb // The text to display on the polygon
                        }
                    });
                    moveToPolygon(result?.data?.abs_abssuburbgeo?.[0]?.geometry?.coordinates)
                } catch (error) {
                    console.error("Error refetching:", error);
                }
            }
        };

        fetchSuburb();
    }, [selectedSuburb, map]);


    // init the map
    useEffect(() => {
        let ignore = false
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY

        // @ts-ignore
        const initializeMap = ({setMap, mapContainer}) => {
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/pascalsun/ckq0th5wt25e117p8smraill2", // style URL
                center: [115.8142283, -31.9810844],
                zoom: 10, // starting zoom
                minZoom: 4,
            })
            map.addControl(new mapboxgl.NavigationControl(), "bottom-left")
            map.on("load", () => {
                if (!ignore) setMap(map)
            })
        }
        if (!map) initializeMap({setMap, mapContainer})
        return () => {
            ignore = true
        }
    }, [map])

    // init the data layers on the map
    useEffect(() => {
        if (map) {
            // @ts-ignore
            map?.resize()
            const im_dis_layer = map.getLayer(`ImmigrantDistribution`)
            if (!im_dis_layer) {
                // @ts-ignore
                map.addSource(`ImmigrantDistributionTileset`, {
                    type: 'vector',
                    // @ts-ignore
                    url: `mapbox://pascalsun.3mquot7g`
                });

                // if the name match, then show a corresponding flag icon to indicate the region
                // @ts-ignore
                map.addLayer({
                    id: `ImmigrantDistributionPolygon`,
                    type: 'fill',
                    source: `ImmigrantDistributionTileset`,
                    // @ts-ignore
                    "source-layer": "country_birth_top_5",
                    "paint": {
                        "fill-color": [
                            "interpolate",
                            ["linear"],
                            ["get", `top_0_percentage`],
                            -1,
                            'rgba(0, 0, 0, 0)',
                            0,
                            "rgba(116,211,251, 1)",
                            60,
                            'rgba(195, 255, 104, 1)',
                        ],
                        "fill-opacity": 0.8
                    }
                })

                // @ts-ignore
                map.addLayer({
                    id: `ImmigrantDistribution`,
                    type: 'symbol',
                    source: `ImmigrantDistributionTileset`,
                    // @ts-ignore
                    "source-layer": "country_birth_top_5",
                    "layout": {
                        "text-field": ["get", `top_${immiDistributionRank}_country`],
                        "text-font": ["Roboto Regular"],
                        "text-size": 16,
                    },
                    //     show different color based on top_0_country_percentage, inter
                    "paint": {
                        "text-color": "#000000"  // This sets the text color to white
                    }
                });

                // @ts-ignore
                map.addLayer({
                    id: 'ImmigrantDistributionLine',
                    type: 'line',
                    source: 'ImmigrantDistributionTileset',
                    'source-layer': 'country_birth_top_5',
                    'paint': {
                        'line-color': '#000000',  // Black color for the boundary
                        'line-width': 2           // Width of the boundary line
                    }
                });

                // @ts-ignore
                map.on("click", `ImmigrantDistributionPolygon`, (e: {
                    features: { properties: { [x: string]: any } }[]
                }) => {
                    setSelectedMapSuburb(e.features[0].properties)
                    setOpenDialog(true)
                    if (window.location.hostname !== "localhost") {
                        ReactGA.event({
                            category: "User",
                            action: 'ImmigrantDistributionPolygon',
                            label: `${e.features[0].properties?.suburb}`
                        });
                    }
                })
            }
            //@ts-ignore
            const selectedSuburbPolygonLayer = map?.getLayer(`selectedSuburbPolygonLayer`)
            if (!selectedSuburbPolygonLayer) {
                // @ts-ignore
                map.addSource('selectedSuburbPolygon', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': [[]], // Empty coordinates
                        }
                    }
                });
                //@ts-ignore
                map.addLayer({
                    'id': 'selectedSuburbPolygonLayer',
                    'type': 'line',
                    'source': 'selectedSuburbPolygon',
                    'layout': {},
                    'paint': {
                        'line-color': '#000000',  // Border color of the polygon
                        'line-width': 5,           // Width of the polygon border
                    }
                });
                // @ts-ignore
                map.addLayer({
                    'id': 'selectedSuburbPolygonTextLayer',
                    'type': 'symbol',
                    'source': 'selectedSuburbPolygon',
                    'layout': {
                        'text-field': '{name}', // Assuming the feature property "name" has the text you want to display
                        'text-anchor': 'center',

                    },
                    "minzoom": 11,
                    'paint': {
                        'text-color': '#00008B' // Dark blue color
                    },
                });
            }
        }
    }, [
        map,
        setOpenDialog,
        setSelectedMapSuburb
    ])
    // switch top 5 country
    useEffect(() => {
        if (map) {
            // @ts-ignore
            const layer = map.getLayer(`ImmigrantDistribution`)
            if (layer) {
                // @ts-ignore
                map?.setLayoutProperty(`ImmigrantDistribution`, "text-field", ["get", `top_${immiDistributionRank}_country`])
            }
        }
    }, [immiDistributionRank, map])


    return (
        <>{
            showControls && <>
                <Autocomplete
                    className={classes.searchBar}
                    freeSolo
                    // @ts-ignore
                    options={metroSuburbs?.filter(suburb => suburb?.sal_name21).map(suburb => suburb.sal_name21)}
                    onChange={(event, newValue) => {
                        setSelectedSuburb(newValue);
                    }}
                    value={selectedSuburb}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("Search suburb")}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                style: {
                                    height: "50px",
                                    padding: "10px",
                                    border: "10px",
                                    width: "250px",
                                    alignItems: "center"
                                } // this adjusts the input height and padding inside
                            }}
                            InputLabelProps={{
                                style: {
                                    marginLeft: "20px" // adjust this value as needed
                                }
                            }}
                            style={{
                                margin: 0,
                                alignItems: "center"
                            }} // to ensure the label and input are correctly aligned
                        />
                    )}
                />

                <Box className={classes.rank}>
                    <FormControl sx={{m: 1, minWidth: 250, maxWidth: 250}} size="small" fullWidth>
                        <InputLabel
                            id="immi-country-rank"
                        >{(t("Ratio Rank (Other than australia local)"))}</InputLabel>
                        <Select
                            labelId="immi-country-rank-label"
                            id="immi-country-rank-select"
                            value={immiDistributionRank}
                            label={t("Ratio Rank")}
                            onChange={e => setImmiDistributionRank(e.target.value)}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        width: 250,
                                    },
                                },
                            }}
                        >
                            {
                                [
                                    {"value": "1", "label": t("immi-rank-1")},
                                    {"value": "2", "label": t("immi-rank-2")},
                                    {"value": "3", "label": t("immi-rank-3")},
                                    {"value": "4", "label": t("immi-rank-4")},
                                    {"value": "5", "label": t("immi-rank-5")},
                                ].map((obj) => {
                                    return <MenuItem value={obj.value}
                                                     key={obj.value}>{obj.label}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>

                </Box>
                <Tooltip title={t("Local population ratio")}>
                    <div
                        id="bottom-right-legend"
                        className={classes.legend}

                    >
                        <div className={classes.legendTitle}>{t("Less")}</div>
                        <div className={classes.legendItemColorBar}>
                            <Paper className={classes.colorBar}/>
                        </div>
                        <div>{t("More")}</div>

                    </div>
                </Tooltip>
            </>
        } <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>{selectedMapSuburb?.suburb}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {/* First Row */}
                    <Grid item container xs={12}>
                        <Grid item xs={12} sm={8}>
                            <Typography
                                variant="body1">{t("Area(sqkm)")}: {selectedMapSuburb?.area_sqkm}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                variant="body1">{t("Total population")}: {selectedMapSuburb?.total_count}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography
                                variant="body1">{t("Largest population")}: {selectedMapSuburb?.top_0_country}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                variant="body1">{t("Ratio")}: {selectedMapSuburb?.top_0_percentage}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography
                                variant="body1">{t("1st")}: {selectedMapSuburb?.top_1_country}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                variant="body1">{t("Ratio")}: {selectedMapSuburb?.top_1_percentage}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography
                                variant="body1">{t("2nd")}: {selectedMapSuburb?.top_2_country}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                variant="body1">{t("Ratio")}: {selectedMapSuburb?.top_2_percentage}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography
                                variant="body1">{t("3rd")}: {selectedMapSuburb?.top_3_country}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                variant="body1">{t("Ratio")}: {selectedMapSuburb?.top_3_percentage}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography
                                variant="body1">{t("4th")}: {selectedMapSuburb?.top_4_country}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                variant="body1">{t("Ratio")}: {selectedMapSuburb?.top_4_percentage}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography
                                variant="body1">{t("5th")}: {selectedMapSuburb?.top_5_country}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                variant="body1">{t("Ratio")}: {selectedMapSuburb?.top_5_percentage}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
            <Button
                className={classes.showFunction}
                fullWidth
                variant="contained"
                startIcon={showControls ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                onClick={toggleControls}
            >
                {showControls ? t('Hide options') : t('Show options')}
            </Button>
            <div
                ref={(el) => {
                    if (el) {
                        // @ts-ignore
                        mapContainer.current = el
                    }
                }}
                className={classes.root}

            />
        </>
    )

}

export default CountryBirthMap