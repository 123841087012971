// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {useParams, useNavigate, Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {isMobileOnly, isMobile} from 'react-device-detect';
import {
    Grid,
    Typography,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Autocomplete,
    TextField,
    Divider
} from '@mui/material';
import {WA_CRIME_SUBURBS, GET_FINANCIAL_YEAR} from "../../gql/get_reports"
import LockIcon from '@mui/icons-material/Lock';
import {GET_SUBURB_CRIME_TYPES, GET_CRIME_MAX_SUBURB_YEAR} from "../../gql/get_crime";
import {useQuery} from "@apollo/client";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    Title,
    PointElement,
    Filler,
    RadialLinearScale
} from 'chart.js';
import {Radar, Pie, Line} from 'react-chartjs-2';
import {CRIME_TYPES, CRIME_TYPES_MAP} from "../../utils/constants"

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale,
    BarElement,
    Title,
    PointElement,
    LineElement,
    Tooltip,
    Filler,
    RadialLinearScale
);


const useStyles = makeStyles((theme) => ({
    container: {
        margin: "5px",
        padding: "5px",
    },
    leftItem: {
        textAlign: "left", // Aligns text to the left
        alignSelf: "center", // Aligns flex item to the center vertically
    },
    rightItem: {
        textAlign: "right", // Aligns text to the right
        alignSelf: "center", // Aligns flex item to the center vertically
    },
    autocompleteContainer: {
        display: 'flex',
        justifyContent: 'flex-end', // Aligns child elements (autocomplete) to the right
    },
    crime: {
        textAlign: "left", // Aligns text to the right
    },

}));

const Suburb = () => {
    const classes = useStyles();
    const {suburbName} = useParams();
    const navigate = useNavigate();
    const [t] = useTranslation();

    const [metroSuburbs, setMetroSuburbs] = useState([])
    const [selectedSuburb, setSelectedSuburb] = useState(suburbName || "Perth")
    const [selectedFinancialYearSuburbCrime, setSelectedFinancialYearSuburbCrime] = useState([])
    const [financialYear, setFinancialYear] = useState("2023-24")
    const [crimeType, setCrimeType] = useState("Dwelling_Burglary")
    const userRole = useSelector(state => state?.login?.role)
    const [selectedCrimeYearly, setSelectedCrimeYearly] = useState("")
    const [radarSuburbCrimeData, setRadarSuburbCrimeData] = useState("")

    const isCurrentMonth = (month: string) => {
        const currentMonth = new Date().getMonth() + 1;
        return month !== currentMonth.toString();
    }

    const {
        refetch: suburbCrimeTypeRefetch
    } = useQuery(GET_SUBURB_CRIME_TYPES, {
        variables: {
            suburbName: selectedSuburb,
            offence: crimeType.replace(/_/g, " ")
        }
    })

    useEffect(() => {
        const fetchSuburbCrimeType = async () => {
            if (selectedSuburb) {
                try {
                    const offence = crimeType.replace(/_/g, " ")
                    const result = await suburbCrimeTypeRefetch({
                        variables: {
                            suburbName: selectedSuburb,
                            offence: offence
                        }
                    });
                    setSelectedCrimeYearly(result?.data?.crime_wacrime)
                } catch (error) {
                    console.error("Error refetching:", error);
                }

            }
        }
        fetchSuburbCrimeType();

    }, [crimeType, selectedSuburb, setSelectedCrimeYearly]);

    const prepareSelectedCrimeYearlyData = (selected_data) => {
        //     labels from financial_year key, remove 2023_2024
        // data from total field
        const labels = []
        const data = []
        const jan = []
        const feb = []
        const mar = []
        const apr = []
        const may = []
        const jun = []
        const jul = []
        const aug = []
        const sep = []
        const oct = []
        const nov = []
        const dec = []
        for (const key in selected_data) {
            if (selected_data[key].financial_year !== "2023-24") {
                labels.push(selected_data[key].financial_year)
                data.push(selected_data[key].total)
                jan.push(selected_data[key].january)
                feb.push(selected_data[key].february)
                mar.push(selected_data[key].march)
                apr.push(selected_data[key].april)
                may.push(selected_data[key].may)
                jun.push(selected_data[key].june)
                jul.push(selected_data[key].july)
                aug.push(selected_data[key].august)
                sep.push(selected_data[key].september)
                oct.push(selected_data[key].october)
                nov.push(selected_data[key].november)
                dec.push(selected_data[key].december)
            }
        }
        if (!isMobileOnly) {
            return {
                labels: labels,
                datasets: [
                    {
                        label: t("Total"),
                        data: data,
                        // backgroundColor will be the same for one suburb
                        backgroundColor: "rgba(255, 99, 132, 0.5)",
                        borderWidth: 1,

                    },
                    {
                        label: t("January"),
                        data: jan,
                        // get another color for the month
                        backgroundColor: "rgba(255, 159, 64, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("1")
                    },
                    {
                        label: t("February"),
                        data: feb,
                        // get another color for the month
                        backgroundColor: "rgba(255, 205, 86, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("2")
                    },
                    {
                        label: t("March"),
                        data: mar,
                        // get another color for the month
                        backgroundColor: "rgba(75, 192, 192, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("3")
                    },
                    {
                        label: t("April"),
                        data: apr,
                        // get another color for the month
                        backgroundColor: "rgba(54, 162, 235, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("4")
                    },
                    {
                        label: t("May"),
                        data: may,
                        // get another color for the month
                        backgroundColor: "rgba(153, 102, 255, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("5")
                    },
                    {
                        label: t("June"),
                        data: jun,
                        // get another color for the month
                        backgroundColor: "rgba(201, 203, 207, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("6")
                    },
                    {
                        label: t("July"),
                        data: jul,
                        // get another color for the month
                        backgroundColor: "rgba(255, 99, 132, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("7")
                    },
                    {
                        label: t("August"),
                        data: aug,
                        // get another color for the month
                        backgroundColor: "rgba(255, 159, 64, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("8")
                    },
                    {
                        label: t("September"),
                        data: sep,
                        // get another color for the month
                        backgroundColor: "rgba(255, 205, 86, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("9")
                    },
                    {
                        label: t("October"),
                        data: oct,
                        // get another color for the month
                        backgroundColor: "rgba(75, 192, 192, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("10")

                    },
                    {
                        label: t("November"),
                        data: nov,
                        // get another color for the month
                        backgroundColor: "rgba(54, 162, 235, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("11")
                    },
                    {
                        label: t("December"),
                        data: dec,
                        // get another color for the month
                        backgroundColor: "rgba(153, 102, 255, 0.5)",
                        borderWidth: 1,
                        hidden: isCurrentMonth("12")
                    }
                ],
            }
        } else {
            return {
                labels: labels,
                datasets: [
                    {
                        label: t("Total"),
                        data: data,
                        // backgroundColor will be the same for one suburb
                        backgroundColor: "rgba(255, 99, 132, 0.5)",
                        borderWidth: 1,

                    },

                ],
            }
        }

    }

    const handleCrimeTypeChange = (event: any) => {
        setCrimeType(event.target.value)
    }

    // select suburb functions
    const {data: metroSuburbsData} = useQuery(
        WA_CRIME_SUBURBS
    )

    const {data: selectedFinancialYearSuburbCrimData, refetch: refetchFinancialYear} = useQuery(
        GET_FINANCIAL_YEAR,
        {
            variables: {
                suburbName: selectedSuburb,
                financialYear: "2023_24"
            }
        }
    )

    useEffect(() => {
        setSelectedFinancialYearSuburbCrime(selectedFinancialYearSuburbCrimData?.crime_wacrime)
    }, [selectedFinancialYearSuburbCrimData]);

    // update suburb options
    useEffect(() => {
        if (metroSuburbsData) {
            setMetroSuburbs(
                metroSuburbsData
            )
        }
    }, [metroSuburbsData, setMetroSuburbs]);

    useEffect(() => {
        const refetchSuburb = async () => {
            const result = await refetchFinancialYear(
                {
                    suburbName: selectedSuburb,
                    financialYear: financialYear
                }
            )
            setSelectedFinancialYearSuburbCrime(result?.data?.crime_wacrime)
        }
        if (selectedSuburb) refetchSuburb();
    }, [selectedSuburb, financialYear]);

    const financialYearOptions = [
        "2013-14",
        "2014-15",
        "2015-16",
        "2016-17",
        "2017-18",
        "2018-19",
        "2019-20",
        "2020-21",
        "2021-22",
        "2022-23",
        "2023-24"
    ]

    const prepareFinancialYearPieData = (financialYearData) => {
        const labels = []
        const values = []
        const labelValueMap = {}
        selectedFinancialYearSuburbCrime?.forEach((crime) => {
            // sort the labels based on ascending order of label, and then sort the values to make sure values stick to the same label order
            labelValueMap[crime?.offence.replace(/\s/g, "_")] = crime?.total
        })
        Object.keys(labelValueMap).sort().forEach((key) => {
            labels.push(t(key))
            values.push(labelValueMap[key])
        })

        return {
            labels: labels,
            datasets: [
                {
                    label: t("Crime"),
                    data: values,
                    backgroundColor: [
                        "#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850",
                        "#8d6e63", "#26a69a", "#ff7043", "#7e57c2", "#78909c",
                        "#42a5f5", "#7b1fa2", "#ffd54f", "#ff8a65", "#ba68c8",
                        "#f48fb1", "#b0bec5"
                    ],
                    borderWidth: 1,
                },
            ],
        }
    }

    // radar chart

    const {refetch: radarSuburbDataRefetch} = useQuery(
        GET_CRIME_MAX_SUBURB_YEAR,
        {
            variables: {
                suburbName: selectedSuburb,
                financialYear: financialYear
            }
        }
    )

    useEffect(() => {
        const fetchRadarSuburbCrimeData = async () => {
            if (selectedSuburb) {
                try {
                    const result = await radarSuburbDataRefetch({
                        variables: {
                            suburbName: selectedSuburb,
                            financialYear: financialYear
                        }
                    });
                    setRadarSuburbCrimeData(result?.data)
                } catch (error) {
                    console.error("Error refetching:", error);
                }

            }
        }
        if (selectedSuburb) fetchRadarSuburbCrimeData();
    }, [selectedSuburb, financialYear]);

    const prepareRadarSuburbCrimeData = () => {

        const labels = []
        const values = []
        radarSuburbCrimeData?.crime_wacrime?.forEach((crime) => {
            labels.push(t(crime?.offence.replace(/\s/g, "_")))
            const maxKey = CRIME_TYPES_MAP[crime?.offence.replace(/\s/g, "_")]
            values.push(crime?.total / radarSuburbCrimeData?.[maxKey]?.aggregate?.max?.total)
        })
        return {
            labels: labels,
            datasets: [
                {
                    label: t("Normalized radar chart (Max = 1)"),
                    data: values,
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                    borderWidth: 1,
                },
            ],
        }
    }


    return (
        <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12} sm={8} className={classes.leftItem}>
                <Typography variant="h5">{selectedSuburb}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.rightItem}>
                <div className={classes.autocompleteContainer}>
                    <Autocomplete
                        freeSolo
                        // @ts-ignore
                        options={metroSuburbs?.crime_wacrime_aggregate?.nodes?.filter(suburb => suburb?.locality).map(suburb => suburb.locality) || []}
                        onChange={(event, newValue) => {
                            setSelectedSuburb(newValue);
                            navigate(`/suburb/${newValue}`);
                        }}
                        value={selectedSuburb}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t("Search suburb")}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    style: {
                                        minWidth: "200px",
                                    }
                                }}
                            />
                        )}
                    />
                </div>
            </Grid>
            {/*add map, areasqkm, population, number of properties, LGA*/}
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={12} container>
                <Grid item xs={12} container>
                    <Grid item xs={12}>
                        <Typography variant="h5">{t("Crime details")}</Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid container item xs={12} sm={3}>
                        <Grid item xs={12}>
                            <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                <InputLabel id="year-select-label">{t('Year')}</InputLabel>
                                <Select
                                    labelId="year-select-label"
                                    id="year-select"
                                    value={financialYear}
                                    onChange={(e) => {
                                        setFinancialYear(e.target.value)
                                    }}
                                    label={t("Year")}
                                >
                                    {financialYearOptions.map((year) => (
                                        <MenuItem key={year} value={year}>{year}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Radar data={prepareRadarSuburbCrimeData()} options={{
                                scales: {
                                    r: {
                                        angleLines: {
                                            display: true
                                        },
                                        suggestedMin: 0, // Min value for the scale
                                        suggestedMax: 1, // Max value for the scale
                                        ticks: {
                                            beginAtZero: true,
                                        }
                                    }
                                },
                            }}/>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} sm={6}>
                        <Grid item xs={12}>
                            <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                                <InputLabel id="crime-type-select">{t("crime-type")}</InputLabel>
                                <Select
                                    labelId="crime-type-select-label"
                                    id="demo-select-small"
                                    value={crimeType}
                                    label={t("crime-type")}
                                    onChange={handleCrimeTypeChange}

                                >
                                    {
                                        CRIME_TYPES.map((crimeType) => {
                                            return <MenuItem value={crimeType}
                                                             key={crimeType}>{t(crimeType)}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                            {userRole === "unregistered_user" ? <>
                                    {/*add register to view line chart for all years*/}
                                    <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                        <LockIcon/>
                                        <Typography
                                            variant="subtitle1">{t("Register to unlock more data and functions.")} <Link
                                            to="/register">{t("Register")}</Link></Typography>
                                    </div>
                                </> :
                                <Line data={prepareSelectedCrimeYearlyData(selectedCrimeYearly)}
                                      options={{
                                          responsive: true,
                                          maintainAspectRatio: true,
                                          plugins: {},
                                          scales: {
                                              x: {
                                                  title: {
                                                      display: true,
                                                      text: t("Year")
                                                  }
                                              },
                                              y: {
                                                  title: {
                                                      display: true,
                                                      text: t("Count")
                                                  }
                                              }
                                          }
                                      }}
                                />
                            }
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sm={3}>
                        <Grid item xs={12}>
                            <Pie data={prepareFinancialYearPieData()} options={{
                                plugins: {
                                    legend: {
                                        display: isMobile ? false : true,
                                    }
                                },
                            }
                            }/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Suburb;