// @ts-nocheck
import {gql} from '@apollo/client';

export const GET_REPORT = gql`
  query GetReport($reportName: String!) {
    hasura_reportstoragetable(where: { report_name: { _eq: $reportName } }) {
      id
      suburb
      value
      normalized_value
    }
  }
`;

// export const GET_REPORTS = gql`
//     query GetReports {
//        hasura_reports {
//         id
//         name
//         user_report_name
//         description
//     }
//   }
// `;

export const SUB_REPORTS = gql`
     subscription ReportsSubscription {
      hasura_reports {
        name
        user_report_name
        id
        description
      }
    }
`;

export const WA_SUBURBS = gql`
  query GetMetroSuburbs {
      abs_abssuburbgeo(where: {ste_code21: {_eq: "5"}}) {
        area_sqkm21
        sal_code21
        sal_name21
      }
}`;


export const WA_CRIME_SUBURBS = gql`
  query GetCrimeSuburbs {
  crime_wacrime_aggregate(distinct_on: locality) {
    nodes {
      locality
    }
  }
}`;


export const GET_SUBURB = gql`
  query GetSuburb($suburbName: String!) {
      abs_abssuburbgeo(where: {ste_code21: {_eq: "5"}, sal_name21: {_eq: $suburbName}}) {
        area_sqkm21
        sal_code21
        sal_name21
        geometry
      }
}`;

export const GET_FINANCIAL_YEAR = gql`
 query CrimeFinancialYear($financialYear: String!, $suburbName: String!) {
  crime_wacrime(where: {locality: {_eq: $suburbName}, financial_year: {_eq: $financialYear}}) {
    id
    financial_year
    january
    february
    march
    april
    may
    june
    july
    august
    september
    october
    november
    december
    total
    offence
    locality
  }
}
`;
