// @ts-nocheck
import {combineReducers} from "redux"
import {crimeReducer} from "./pages/Map/reducer"
import loginReducer from "./pages/Auth/reducer"
import uiReducer from "./redux/ui"

const appReducer = combineReducers({
    // add reducers here
    "ui": uiReducer,
    "WaMap": crimeReducer,
    "login": loginReducer
})

export type RootState = ReturnType<typeof appReducer>

export default appReducer
