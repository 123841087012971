// @ts-nocheck
import React, {useState} from 'react';
import {Container, TextField, Button, Typography, Box} from '@mui/material';
import axiosObj from "../../api/axios";
import {Link, useNavigate} from 'react-router-dom';
import {setLogin, setUserProfile} from "./reducer";
import {useDispatch} from "react-redux"
import {jwtDecode} from 'jwt-decode';
import {useTranslation} from 'react-i18next';
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {         // Adjust according to your UI
    }
}));

const LoginPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(null);
    const navigate = useNavigate();
    const [t] = useTranslation();

    const handleLogin = async () => {
        setLoginError(null);
        try {
            const response = await axiosObj.post('subscription/token/', {
                "username": username,
                "password": password
            });

            const token = response.data?.data?.access; // Adjust according to your API response
            dispatch(setLogin())
            const decoded = jwtDecode(token);
            dispatch(setUserProfile(
                decoded?.role,
                decoded?.username,
                decoded?.first_name,
                decoded?.last_name
            ))
            localStorage.setItem('jwt', token);
            localStorage.setItem('refreshJWT', response.data?.data?.refresh);
            navigate("/")
        } catch (error) {
            console.error('Login error:', error.response ? error.response.data : error);
            setLoginError(error.response ? error.response.data?.detail : error);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    {t("Sign in")}
                </Typography>
                <Box sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label={t("Username")}
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t("Password")}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button

                        fullWidth
                        variant="contained"
                        onClick={handleLogin}
                        sx={{mt: 3, mb: 2}}
                    >
                        {t("Sign in")}
                    </Button>
                </Box>
                <Box>
                    <Typography>
                        {t(loginError)}
                    </Typography>
                </Box>
                <Box>
                    <Typography>{t("Have not registered yet?")}
                        <Link
                            to="/register">{t("Click here")}</Link>
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        variant="body2"
                    >{t("Forgot password?")}
                        Contact us at <a href="mailto:findperth.com@gmail.com">findperth.com@gmail.com</a>
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default LoginPage;
